import React from "react";
import "./Anfunken.css";

export function Anfunken() {
  return (
    <div className="anfunkenwrapper">
      <div className="anfunkencontentwrapper">
        <div className="anfunkentextwrapper">
          <h1 className="anfunkenheading">Anfunken</h1>
          <p className="anfunkentext">
            Du möchtest beim nächsten Event selbst etwas{" "}
            <b>Kreatives anbieten</b>, beim <b>Aufbau helfen</b> oder hast sonst{" "}
            <b>irgendwelche Fragen?</b>
            <br />
            <br />
            Hier findest du für all deine Anliegen den richtigen Kanal – Wir
            freuen uns auf deine Nachricht!
          </p>
        </div>
        <div className="anfunkenkategoriewrapper">
          <div className="anfunkenkategorie kategorie1">
            <h2 className="anfunkenkategorieheading">
              Kreatives
              <br className="headingbreak" />
              anbieten
            </h2>
            <p className="anfunkenkategorietext">
              Du hast eine Aktions-Idee oder wolltest schon immer mal einen
              Workshop leiten, wusstest nur nie wie oder vor welchem Publikum?
              Werde bei uns selbst als Leiter:in aktiv und teile, was dich
              bewegt: Alles, was es braucht, ist mit vollem Herzen dabei zu
              sein!
            </p>
            <a
              className="button anfunkenkategoriebutton"
              href="mailto:mail@luftundlaune.art?subject=Kreatives anbieten"
            >
              <p className="buttontext pink">Biete an!</p>
              <i className="icon-link-extern pink"></i>
            </a>
          </div>
        </div>
        <div className="anfunkenkategoriewrapper">
          <div className="anfunkenkategorie kategorie2">
            <h2 className="anfunkenkategorieheading">
              Teil
              <br className="headingbreak" />
              werden
            </h2>
            <p className="anfunkenkategorietext">
              Wir suchen immer motivierte Menschen, die sich beteiligen wollen
              und uns in verschiedenen Bereichen aktiv unterstützen – von
              Glitzern über Deko, Technik, Kochen, Awareness bis Feuer löschen
              ist alles drin: Sei dabei!
            </p>
            <a
              className="button anfunkenkategoriebutton"
              href="https://forms.gle/dG48NmTZ4Jgh59c28"
            >
              <p className="buttontext pink">Pack mit an!</p>
              <i className="icon-link-extern pink"></i>
            </a>
          </div>
        </div>
        <div className="anfunkenkategoriewrapper">
          <div className="anfunkenkategorie kategorie3">
            <h2 className="anfunkenkategorieheading">
              Frage
              <br className="headingbreak" />
              stellen
            </h2>
            <p className="anfunkenkategorietext">
              Schreibe uns bei Fragen aller Art, Feedback zur letzten
              Veranstaltung oder Anregungen für die nächste gerne eine Nachricht
              – Wir freuen uns auf deinen Input!
            </p>
            <a
              className="button anfunkenkategoriebutton"
              href="mailto:mail@luftundlaune.art?subject=Frage"
            >
              <p className="buttontext pink">Frag los!</p>
              <i className="icon-link-extern pink"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
