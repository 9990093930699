import React, { useState } from "react";
import "./Intro.css";

export function Intro() {
  return (
    <div className="introwrapper">
      <p className="intro white introtext">
        Seit 2020 rufen wir Veranstaltungen ins Leben, die Kreative
        zusammenbringen und Neues Kennenlernen lassen sollen. Bei uns gehts
        nicht nur ums Dabeisein, sondern darum sich auszuprobieren, der Fantasie
        freien Lauf zu lassen, zu kreieren und zu staunen.
      </p>
    </div>
  );
}
