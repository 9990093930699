import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [clicked, setclicked] = useState(false);
  const [active, setactive] = useState(false);

  const handleClick = () => {
    setclicked(!clicked);
    if (!clicked) {
      if (active) {
        handleActive();
      }
    }
  };

  const changeClasses = () => {
    var overpoint = document.querySelector(".overpoint");
    var underpoint = document.querySelector(".underpoint");
    var arrowdown = document.querySelector(".icon-arrow-small-down");
    var arrowup = document.querySelector(".icon-arrow-small-up");

    if (active) {
      overpoint.classList.toggle("overpoint-active");
      underpoint.classList.toggle("underpoint-active");
      arrowup.classList.toggle("icon-arrow-small-down");
      arrowup.classList.toggle("icon-arrow-small-up");
    } else {
      overpoint.classList.toggle("overpoint-active");
      underpoint.classList.toggle("underpoint-active");
      arrowdown.classList.toggle("icon-arrow-small-down");
      arrowdown.classList.toggle("icon-arrow-small-up");
    }
  };

  const handleActive = () => {
    setactive(!active);
    changeClasses();
  };

  return (
    <nav className={clicked ? "navbar-items active" : "navbar-items"}>
      <div className="navbar-wrapper">
        <div className="menu-icon" onClick={handleClick}>
          <div className="menu-logo"></div>
        </div>
      </div>
      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        <li className="menu-close" onClick={handleClick}>
          <i className="icon-close"></i>
        </li>
        <li className="nav-links-wrapper">
          <Link className="nav-links" to="/" onClick={handleClick}>
            <p className="nav-links-text">Startseite</p>
            <i className="icon-arrow-right"></i>
          </Link>
        </li>
        {/*<li className="nav-links-wrapper">
          <Link className="nav-links" to="/Next_Event" onClick={handleClick}>
            <p className="nav-links-text">Nächstes Event</p>
            <i className="icon-arrow-right"></i>
          </Link>
        </li>*/}
        <li className="nav-links-wrapper">
          <div className="nav-links overpoint" onClick={handleActive}>
            <p className="nav-links-text">Über Uns</p>
            <i className="icon-arrow-small-down"></i>
          </div>
          <div className="underpoint">
            <li className="nav-links-wrapper">
              <Link className="nav-links" to="/Konzept" onClick={handleClick}>
                <p className="nav-links-text">Konzept</p>
                <i className="icon-arrow-right"></i>
              </Link>
            </li>
            <li className="nav-links-wrapper">
              <Link className="nav-links" to="/Awareness" onClick={handleClick}>
                <p className="nav-links-text">Awareness</p>
                <i className="icon-arrow-right"></i>
              </Link>
            </li>
          </div>
        </li>
        <li className="nav-links-wrapper">
          <a
            className="nav-links"
            href="https://www.picdrop.com/mrleonhirsch/mkPkZXjCj4"
            onClick={handleClick}
          >
            <p className="nav-links-text">Galerie</p>
            <i className="icon-link-extern"></i>
          </a>
        </li>
        <li className="nav-links-wrapper">
          <Link className="nav-links" to="/Kontakt" onClick={handleClick}>
            <p className="nav-links-text">Kontakt</p>
            <i className="icon-arrow-right"></i>
          </Link>
        </li>
        {/* <li className="nav-links-wrapper">
          <Link className="nav-links" to="/FAQ" onClick={handleClick}>
            <p className="nav-links-text">FAQ</p>
            <i className="icon-arrow-right"></i>
          </Link>
        </li> */}
        <li className="nav-links-wrapper">
          <a
            className="nav-links"
            href="https://www.yesticket.org/events/de/luftlaune/"
          >
            <p className="nav-links-text">Tickets</p>
            <i className="icon-link-extern"></i>
          </a>
        </li>

        <li className="nav-other-links-wrapper">
          <a
            className="nav-other-links"
            href="https://www.instagram.com/luftundlaune.art/"
          >
            <i className="icon-service_insta"></i>
          </a>
          <a
            className="nav-other-links"
            id="mail-link"
            href="mailto:mail@luftundlaune.art"
          >
            <i className="icon-service_mail"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};
