import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Nextevent.css";

export function Nextevent() {
  return (
    <div className="nexteventwrapper">
      <div className="nexteventflexcontainer">
        <h1 className="mainheading">Nächstes Event</h1>
        <div className="dateflex">
          <div className="datesection">
            <h1 className="date">10+11</h1>
            <h1 id="period" className="date">
              .
            </h1>
          </div>
          <div className="datesection">
            <h1 className="date">05</h1>
            <h1 id="period" className="date">
              .
            </h1>
          </div>
        </div>
        <p className="datetext">New Art Festival</p>
        {/*<p className="datetext">
          Es steht noch kein neuer Termin fest. Bis dahin werdet ihr auf
          Instagram auf dem Laufenden gehalten.
        </p>*/}
        {/*<Link className="button" to="/Next_Event">
          <p className="buttontext purple">Infos zum nächsten Termin</p>
          <i className="icon-arrow-right purple"></i>
        </Link>*/}
        <div className="nexteventlinks">
          <a
            className="button"
            href="https://www.yesticket.org/events/de/luftlaune/"
          >
            <p className="buttontext purple">Tickets</p>
            <i className="icon-link-extern purple"></i>
          </a>
          <a
            className="button"
            href="https://www.instagram.com/luftundlaune.art/"
          >
            <p className="buttontext purple">Infos zum nächsten Termin</p>
            <i className="icon-link-extern purple"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
