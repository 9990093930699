import React, { useEffect } from "react";
import "./Konceptcontent.css";
import { ScrolltoTopbutton } from "../../ScrolltoTopbutton/ScrolltoTopbutton";
import { Link } from "react-router-dom";
import { Nextevent } from "../Homecontent/Nextevent/Nextevent";
import { Keyfigures } from "./Keyfigures/Keyfigures";
import Video from "../../../sources/video/luf_concept_vid.gif";
import Placeholder from "../../../sources/video/luf_concept_vid_start.gif";

export function Konceptcontent() {
  useEffect(() => {
    const o = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          setTimeout(() => {
            entry.target.src = Video;
          }, 1300);
          if (entry.isIntersecting) {
            var el = document.querySelector(".koncept-gif-text");
            setTimeout(() => {
              el.classList.add("show");
            }, 2600);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
        rootMargin: "-50px",
      }
    );

    const konceptgif = document.querySelectorAll("#konceptlogogif");

    konceptgif.forEach((konceptlogogif) => {
      o.observe(konceptlogogif);
    });
  });

  return (
    <div className="koncept-flex-wrapper">
      <main className="koncept-wrapper">
        <section className="koncept-heading-section">
          <h1>Was wir machen</h1>
          <p>
            Seit 2020 rufen wir Veranstaltungen ins Leben, die Kreative
            zusammenbringen und Neues Kennenlernen lassen sollen.
          </p>
        </section>
        <section className="koncept-gif-section">
          <h2>Konzept</h2>
          <img id="konceptlogogif" src={Placeholder} />
          <div className="koncept-gif-text">
            <h2>spüren, tanzen, kreieren</h2>
            <p>
              Luft&Laune ist mehr. Mehr Kreativität, mehr Kennenlernen, mehr
              losgelöst sein.{" "}
            </p>
            <p>
              Unsere Events sollen für alle sein und alle sollen sich ihre
              individuelle Zeit gestalten wie es beliebt. Wir setzen den Rahmen,
              schaffen das Angebot und du machst wonach dir ist.
            </p>
          </div>
        </section>
        <section className="price-section">
          <h2>Ein Ticket finanziert ...</h2>
          <svg
            className="price-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 792 455.159"
          >
            <g id="graph">
              <g id="fade_in" data-name="fade in">
                <polyline
                  points="446.258 176.059 446.258 26.103 792 26.103"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <polyline
                  points="340.92 164.73 340.92 97.759 0 97.759"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <polyline
                  points="380.251 164.73 380.251 26.103 0 26.103"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <polyline
                  points="307.281 210.298 307.281 192.463 0 192.463"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <polyline
                  points="263.319 308.33 263.319 272.66 0 272.66"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <polyline
                  points="446.258 402.036 446.258 411.387 791.234 411.387"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
              </g>
              <g id="text">
                <circle
                  cx="395.968"
                  cy="294.934"
                  r="157.977"
                  transform="translate(-92.573 366.376) rotate(-45)"
                  fill="#17082F"
                />
                <path
                  d="m395.968,141.957c40.862,0,79.278,15.912,108.171,44.806,28.894,28.894,44.806,67.309,44.806,108.171s-15.912,79.278-44.806,108.171c-28.894,28.894-67.309,44.806-108.171,44.806s-79.278-15.912-108.171-44.806c-28.894-28.894-44.806-67.309-44.806-108.171s15.912-79.278,44.806-108.171c28.894-28.894,67.309-44.806,108.171-44.806m0-10c-90.01,0-162.977,72.967-162.977,162.977s72.967,162.977,162.977,162.977,162.977-72.967,162.977-162.977-72.967-162.977-162.977-162.977h0Z"
                  fill="#17082F"
                />
              </g>
              <g id="donut_1" data-name="donut 1">
                <g>
                  <path
                    d="m360.135,131.781l3.927-.763c10.895-2.117,20.703-3.062,31.8-3.062h4v63.228l-3.997.003c-6.614.006-13.255.646-19.741,1.902l-3.924.76-12.065-62.068Z"
                    fill="#f32583"
                    fill-rule="evenodd"
                  />
                  <path
                    d="m395.862,131.957v55.231c-7.01.007-13.861.69-20.498,1.975l-10.539-54.217c10.582-2.057,20.257-2.989,31.037-2.989m8-8h-8c-11.364,0-21.407.967-32.563,3.135l-7.853,1.526,1.526,7.853,10.539,54.217,1.525,7.848,7.849-1.52c6.235-1.207,12.622-1.823,18.985-1.829l7.992-.008v-71.223h0Z"
                    fill="#17082F"
                  />
                </g>
                <g>
                  <path
                    d="m319.208,146.577l3.595-1.753c13.271-6.472,26.766-10.988,41.258-13.805l3.926-.764,12.066,62.073-3.93.761c-8.875,1.719-17.49,4.599-25.604,8.56l-3.596,1.755-27.716-56.828Z"
                    fill="#f32583"
                    fill-rule="evenodd"
                  />
                  <path
                    d="m364.825,134.945l10.539,54.217c-9.354,1.812-18.277,4.83-26.598,8.892l-24.209-49.635c12.919-6.301,26.159-10.731,40.268-13.473m6.327-9.379l-7.853,1.526c-14.84,2.885-28.66,7.509-42.248,14.136l-7.19,3.507,3.507,7.19,24.209,49.635,3.508,7.193,7.192-3.51c7.799-3.807,16.079-6.575,24.61-8.227l7.859-1.522-1.528-7.858-10.539-54.217-1.526-7.853h0Z"
                    fill="#17082F"
                  />
                </g>
                <g>
                  <path
                    d="m233.187,258.178l.967-3.881c12.476-50.035,42.301-86.867,88.65-109.473l3.595-1.753,27.715,56.825-3.594,1.754c-27.6,13.472-47.709,38.226-55.17,67.913l-.973,3.872-61.19-15.256Z"
                    fill="#f32583"
                    fill-rule="evenodd"
                  />
                  <path
                    d="m324.557,148.419l24.209,49.636c-28.195,13.763-49.488,39.468-57.296,70.533l-53.435-13.323c12.17-48.812,41.306-84.793,86.522-106.846m3.683-10.697l-7.19,3.507c-47.461,23.148-78.003,60.864-90.777,112.101l-1.935,7.762,7.762,1.935,53.435,13.323,7.748,1.932,1.946-7.744c7.174-28.541,26.508-52.34,53.046-65.294l7.187-3.508-3.506-7.188-24.209-49.635-3.507-7.19h0Z"
                    fill="#17082F"
                  />
                </g>
                <g>
                  <path
                    d="m317.621,441.765c-33.487-17.805-59.192-45.198-74.339-79.217-15.146-34.02-18.303-71.452-9.128-108.251l.968-3.881,61.206,15.26-.977,3.887c-2.077,8.262-3.13,16.798-3.13,25.372,0,38.326,21.01,73.39,54.83,91.509l3.515,1.883-29.413,55.318-3.532-1.878Z"
                    fill="#f32583"
                    fill-rule="evenodd"
                  />
                  <path
                    d="m238.035,255.264l53.435,13.323c-2.119,8.432-3.25,17.257-3.25,26.347,0,41.134,23.053,76.88,56.941,95.035l-25.663,48.265c-66.627-35.426-99.719-109.75-81.463-182.969m-5.827-9.698l-1.935,7.762c-4.703,18.865-6.234,38.088-4.549,57.137,1.647,18.617,6.325,36.687,13.903,53.709,7.579,17.022,17.878,32.59,30.611,46.271,13.028,13.998,28.338,25.724,45.505,34.851l7.064,3.756,3.756-7.064,25.663-48.265,3.744-7.042-7.03-3.766c-32.518-17.421-52.719-51.134-52.719-87.983,0-8.244,1.012-16.453,3.009-24.397l1.954-7.773-7.777-1.939-53.435-13.323-7.762-1.935h0Z"
                    fill="#17082F"
                  />
                </g>
                <g>
                  <path
                    d="m395.894,461.317c-17.448,0-34.787-2.76-51.532-8.201-10.5-3.412-16.998-6.17-26.741-11.351l-3.532-1.878,29.424-55.34,3.537,1.895c14.946,8.007,31.862,12.239,48.918,12.239,45.228,0,84.921-28.906,98.771-71.929l1.229-3.818,59.632,19.375-1.236,3.804c-11.095,34.146-32.352,63.198-61.473,84.015-28.542,20.402-62.081,31.187-96.991,31.187h-.005Z"
                    fill="#f32583"
                    fill-rule="evenodd"
                  />
                  <path
                    d="m498.546,327.979l52.013,16.9c-22.335,68.74-86.096,112.438-154.664,112.438-16.656,0-33.6-2.58-50.297-8.005-10.252-3.331-16.581-6.018-26.099-11.078l25.663-48.265c15.137,8.109,32.433,12.713,50.807,12.713,47.979,0,88.626-31.363,102.578-74.703m-5.157-10.087l-2.458,7.636c-6.417,19.935-18.753,37.095-35.674,49.626-17.251,12.775-37.752,19.528-59.288,19.528-16.4,0-32.662-4.068-47.029-11.765l-7.074-3.79-3.768,7.086-25.663,48.265-3.756,7.064,7.064,3.756c9.98,5.306,16.633,8.131,27.383,11.623,17.147,5.572,34.902,8.396,52.769,8.396s35.427-2.789,52.417-8.291c16.777-5.432,32.558-13.387,46.905-23.642,29.822-21.317,51.59-51.067,62.951-86.032l2.472-7.608-7.608-2.472-52.013-16.9-7.629-2.479h0Z"
                    fill="#17082F"
                  />
                </g>
                <g>
                  <path
                    d="m493.516,330.55l1.222-3.797c3.303-10.261,4.978-20.966,4.978-31.819,0-57.207-46.541-103.748-103.748-103.748l-4.056.057-.05-4.056v-59.231h4c91.896,0,166.658,74.763,166.658,166.658,0,18.501-2.439,33.903-8.157,51.5l-1.236,3.805-59.611-19.369Z"
                    fill="#f32583"
                    fill-rule="evenodd"
                  />
                  <path
                    d="m395.862,131.957c89.834,0,162.658,72.825,162.658,162.658,0,17.967-2.409,33.177-7.961,50.264l-52.013-16.9c3.352-10.414,5.17-21.516,5.17-33.045,0-59.507-48.24-107.748-107.748-107.748-.035,0-.071.001-.106.001v-55.231m0-8h-8v71.241l8.01-.01h.124c54.987.015,99.72,44.755,99.72,99.746,0,10.436-1.61,20.729-4.785,30.594l-2.445,7.594,7.588,2.465,52.013,16.9,7.608,2.472,2.472-7.608c5.777-17.778,8.353-34.043,8.353-52.736,0-23.035-4.513-45.386-13.414-66.43-8.596-20.323-20.9-38.573-36.57-54.243-15.67-15.67-33.92-27.974-54.243-36.57-21.045-8.901-43.395-13.414-66.43-13.414h0Z"
                    fill="#17082F"
                  />
                </g>
              </g>
            </g>
            <g id="DE">
              <g id="fade_in-2" data-name="fade in">
                <g id="_3_Notgroschen" data-name="3 % Notgroschen">
                  <path
                    d="m5.94,16.751c-.673,0-1.323-.089-1.953-.266s-1.196-.441-1.697-.792-.898-.787-1.192-1.31c-.294-.521-.44-1.128-.44-1.818h3.068c.036.295.145.56.324.797.181.237.428.425.743.562.314.138.697.207,1.147.207.611,0,1.101-.132,1.467-.396.365-.265.549-.601.549-1.009,0-.281-.072-.513-.216-.692-.145-.181-.353-.313-.626-.4-.272-.087-.607-.131-1.003-.131h-1.836v-2.259h1.791c.479,0,.854-.12,1.125-.36.27-.24.404-.555.404-.945,0-.354-.129-.646-.387-.877-.258-.231-.681-.347-1.269-.347-.511,0-.967.105-1.368.315-.402.21-.64.546-.711,1.008H.801c.072-.9.327-1.64.766-2.219.438-.579,1.02-1.008,1.745-1.287.727-.279,1.555-.418,2.484-.418.948,0,1.79.144,2.524.432.735.288,1.314.69,1.737,1.206s.635,1.119.635,1.809c0,.595-.155,1.119-.464,1.575-.31.456-.775.789-1.399.999.696.276,1.243.655,1.643,1.139.398.483.599,1.063.599,1.741,0,.732-.218,1.38-.652,1.944-.436.564-1.038,1.004-1.81,1.318-.771.315-1.66.473-2.668.473Z"
                    fill="#fff"
                  />
                  <path
                    d="m19.106,9.884c-.468,0-.901-.104-1.301-.311-.398-.207-.718-.526-.958-.958-.24-.433-.36-.979-.36-1.639,0-.647.12-1.184.36-1.606s.56-.737.958-.944c.399-.207.833-.311,1.301-.311.486,0,.93.104,1.332.311s.723.521.963.944.36.959.36,1.606c0,.66-.12,1.206-.36,1.639-.24.432-.561.751-.963.958s-.846.311-1.332.311Zm-1.898,6.579l8.234-12.078h2.862l-8.208,12.078h-2.889Zm1.898-8.343c.174,0,.313-.078.419-.234.104-.156.157-.459.157-.909,0-.426-.053-.712-.157-.859-.105-.146-.245-.221-.419-.221-.168,0-.3.074-.396.221-.097.147-.145.434-.145.859,0,.45.048.753.145.909.096.156.228.234.396.234Zm7.335,8.559c-.468,0-.901-.104-1.3-.311-.399-.207-.719-.524-.959-.954-.24-.429-.36-.97-.36-1.624,0-.666.12-1.214.36-1.643s.56-.747.959-.954c.398-.207.832-.311,1.3-.311.475,0,.912.104,1.314.311.401.207.726.525.972.954s.369.977.369,1.643c0,.654-.123,1.195-.369,1.624-.246.43-.57.747-.972.954-.402.207-.84.311-1.314.311Zm0-1.782c.168,0,.303-.074.405-.225.102-.149.153-.444.153-.882s-.052-.736-.153-.896c-.103-.159-.237-.238-.405-.238s-.301.079-.4.238c-.099.159-.148.457-.148.896s.05.732.148.882c.1.15.232.225.4.225Z"
                    fill="#fff"
                  />
                  <path
                    d="m1.728,36.998h1.953l5.554,8.892h-.136v-8.892h1.71v11.466h-1.817l-5.823-9.288h.271v9.288h-1.711v-11.466Z"
                    fill="#fff"
                  />
                  <path
                    d="m17.577,48.698c-.786,0-1.489-.171-2.11-.514-.621-.342-1.111-.852-1.472-1.529-.359-.679-.54-1.516-.54-2.512,0-1.014.181-1.858.54-2.533.36-.675.851-1.184,1.472-1.525s1.324-.513,2.11-.513c.774,0,1.471.171,2.088.513.618.342,1.105.851,1.463,1.525.356.675.535,1.52.535,2.533,0,.996-.179,1.833-.535,2.512-.357.678-.845,1.188-1.463,1.529-.617.343-1.313.514-2.088.514Zm-.054-1.494c.498,0,.929-.12,1.292-.36.362-.24.642-.59.837-1.049.194-.459.292-1.009.292-1.651,0-.983-.199-1.746-.599-2.286-.398-.54-.974-.81-1.724-.81-.504,0-.938.121-1.3.364-.363.243-.643.595-.837,1.054-.195.459-.293,1.019-.293,1.678,0,.967.201,1.718.604,2.255.401.537.978.806,1.728.806Z"
                    fill="#fff"
                  />
                  <path
                    d="m23.147,40.966v-1.161h5.589v1.161h-5.589Zm3.942,7.731c-.492,0-.937-.094-1.332-.279-.396-.186-.71-.467-.94-.842-.231-.375-.347-.848-.347-1.417v-8.712h1.701v8.657c0,.816.381,1.225,1.143,1.225.234,0,.462-.025.685-.077.222-.051.474-.127.756-.229l.243,1.216c-.318.132-.632.241-.94.328-.31.087-.632.131-.968.131Z"
                    fill="#fff"
                  />
                  <path
                    d="m34.407,52.927c-.937,0-1.742-.129-2.417-.387s-1.195-.604-1.562-1.035-.549-.903-.549-1.413c0-.647.237-1.145.711-1.489s1.077-.518,1.809-.518l1.107.521c-.624,0-1.105.126-1.444.379-.339.252-.509.594-.509,1.025,0,.45.246.82.738,1.111s1.197.437,2.115.437c.666,0,1.251-.098,1.755-.292.504-.195.894-.45,1.17-.766.275-.314.414-.652.414-1.012,0-.246-.089-.455-.266-.626s-.445-.257-.806-.257h-3.168c-.588,0-1.105-.091-1.553-.274-.447-.183-.796-.424-1.048-.725-.252-.3-.379-.633-.379-.998,0-.36.103-.671.307-.932.204-.262.474-.459.81-.595.336-.135.705-.202,1.107-.202l.387.594c-.174,0-.348.038-.521.112-.175.075-.318.188-.433.338-.114.15-.171.336-.171.558,0,.259.109.473.329.644.219.172.607.257,1.165.257h3.168c.864,0,1.542.171,2.034.513.492.343.738.841.738,1.494,0,.595-.204,1.161-.612,1.701s-.988.981-1.741,1.323c-.754.342-1.649.513-2.687.513Zm.188-7.064c-.672,0-1.282-.131-1.831-.392-.55-.262-.985-.627-1.31-1.099-.324-.471-.486-1.021-.486-1.651,0-.636.162-1.189.486-1.66s.76-.837,1.31-1.099c.549-.261,1.159-.391,1.831-.391.654,0,1.253.13,1.796.391.543.262.978.628,1.305,1.099s.49,1.024.49,1.66c0,.63-.163,1.181-.49,1.651-.327.472-.762.837-1.305,1.099-.543.261-1.142.392-1.796.392Zm0-1.206c.618,0,1.091-.185,1.418-.554.326-.369.49-.829.49-1.382,0-.576-.164-1.041-.49-1.395-.327-.354-.8-.531-1.418-.531-.606,0-1.078.177-1.417.531-.34.354-.509.818-.509,1.395,0,.553.169,1.013.509,1.382.339.369.811.554,1.417.554Zm2.943-3.699l-.801-.387c0-.517.107-.967.323-1.351s.517-.684.9-.899c.384-.217.825-.324,1.323-.324l-.108,1.665c-.534,0-.94.104-1.22.314-.278.21-.418.537-.418.981Z"
                    fill="#fff"
                  />
                  <path
                    d="m41.517,48.463v-8.685h1.71v8.685h-1.71Zm1.242-3.87c0-1.109.115-2.032.347-2.768.23-.734.555-1.282.972-1.643.417-.359.907-.54,1.472-.54.168,0,.325.013.473.036.146.024.292.058.437.1l-.063,1.7c-.162-.042-.337-.079-.526-.112-.188-.033-.361-.05-.518-.05-.492,0-.894.131-1.206.392-.312.262-.543.637-.692,1.125-.15.489-.226,1.076-.226,1.76h-.468Z"
                    fill="#fff"
                  />
                  <path
                    d="m51.515,48.698c-.786,0-1.489-.171-2.11-.514-.621-.342-1.111-.852-1.472-1.529-.359-.679-.54-1.516-.54-2.512,0-1.014.181-1.858.54-2.533.36-.675.851-1.184,1.472-1.525s1.324-.513,2.11-.513c.774,0,1.471.171,2.088.513.618.342,1.105.851,1.463,1.525.356.675.535,1.52.535,2.533,0,.996-.179,1.833-.535,2.512-.357.678-.845,1.188-1.463,1.529-.617.343-1.313.514-2.088.514Zm-.054-1.494c.498,0,.929-.12,1.292-.36.362-.24.642-.59.837-1.049.194-.459.292-1.009.292-1.651,0-.983-.199-1.746-.599-2.286-.398-.54-.974-.81-1.724-.81-.504,0-.938.121-1.3.364-.363.243-.643.595-.837,1.054-.195.459-.293,1.019-.293,1.678,0,.967.201,1.718.604,2.255.401.537.978.806,1.728.806Z"
                    fill="#fff"
                  />
                  <path
                    d="m61.415,48.698c-.84,0-1.548-.119-2.124-.355-.576-.237-1.016-.567-1.318-.99s-.461-.92-.473-1.489h1.611c.023.498.224.866.599,1.106s.883.36,1.525.36c.342,0,.652-.055.932-.162.278-.108.501-.255.666-.441.165-.186.247-.396.247-.63,0-.294-.107-.526-.324-.697-.216-.171-.563-.322-1.044-.454l-1.772-.604c-.708-.24-1.251-.56-1.629-.958-.378-.399-.567-.878-.567-1.436,0-.714.318-1.289.954-1.724.636-.436,1.479-.652,2.529-.652,1.086,0,1.924.229,2.515.688.592.459.899,1.033.923,1.723h-1.646c-.042-.342-.212-.604-.509-.787-.297-.183-.725-.274-1.282-.274-.522,0-.932.114-1.229.342-.297.229-.446.486-.446.774,0,.516.444.899,1.332,1.151l1.863.657c.708.229,1.254.522,1.638.882.385.36.576.816.576,1.368,0,.444-.146.864-.44,1.26-.294.396-.707.719-1.238.968-.53.249-1.153.374-1.867.374Z"
                    fill="#fff"
                  />
                  <path
                    d="m70.874,48.698c-.708,0-1.368-.189-1.98-.567-.611-.378-1.104-.909-1.476-1.593-.372-.685-.559-1.488-.559-2.412,0-.972.187-1.796.559-2.471s.864-1.19,1.476-1.548c.612-.357,1.272-.535,1.98-.535.521,0,.986.082,1.395.247s.759.387,1.053.666.533.593.716.94.311.708.383,1.08l-1.737.09c-.126-.45-.347-.812-.661-1.084-.315-.273-.704-.41-1.166-.41-.39,0-.757.109-1.103.329-.345.219-.624.552-.837.999-.213.446-.319,1.012-.319,1.696,0,.636.099,1.179.297,1.629s.468.795.811,1.035c.342.24.726.359,1.151.359.612,0,1.069-.148,1.373-.445.303-.297.454-.706.454-1.229h1.737c0,.606-.142,1.152-.423,1.639-.282.485-.688.871-1.22,1.156s-1.166.428-1.903.428Z"
                    fill="#fff"
                  />
                  <path
                    d="m77.111,48.463v-12.303h1.71v12.303h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m91.232,48.698c-.744,0-1.416-.192-2.017-.576-.6-.384-1.075-.918-1.426-1.603-.352-.684-.527-1.476-.527-2.376,0-.954.175-1.771.522-2.452s.822-1.204,1.422-1.57,1.275-.549,2.025-.549c.672,0,1.247.136,1.728.409s.867.639,1.161,1.098.496.979.607,1.562.131,1.183.059,1.8h-6.156v-.989h4.986l-.414.27c.018-.324,0-.647-.05-.972s-.148-.623-.292-.896c-.145-.273-.347-.492-.607-.657-.262-.165-.593-.247-.995-.247-.504,0-.926.126-1.265.378s-.595.589-.77,1.013c-.174.423-.261.886-.261,1.39v.693c0,.558.084,1.053.252,1.485.168.432.419.772.752,1.021s.754.373,1.265.373c.498,0,.91-.117,1.237-.351.327-.234.529-.585.607-1.054h1.746c-.09.564-.297,1.057-.621,1.477s-.739.745-1.246.977c-.508.23-1.082.347-1.724.347Z"
                    fill="#fff"
                  />
                  <path
                    d="m97.496,48.463v-8.658h1.485l.225,1.872v6.786h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                </g>
                <g
                  id="_5_Fahrtkosten_Crew_Verpflegung"
                  data-name="5 % Fahrtkosten /Crew Verpflegung"
                >
                  <path
                    d="m6.354,75.394h2.826v7.263h2.106v2.484h-2.106v2.421h-2.861v-2.421H.324v-2.151l6.029-7.596Zm.127,4.131l-2.755,3.465h2.755v-3.465Z"
                    fill="#fff"
                  />
                  <path
                    d="m18.72,80.983c-.468,0-.901-.104-1.301-.311-.398-.207-.718-.526-.958-.958-.24-.433-.36-.979-.36-1.639,0-.647.12-1.184.36-1.606s.56-.737.958-.944c.399-.207.833-.311,1.301-.311.486,0,.93.104,1.332.311s.723.521.963.944.36.959.36,1.606c0,.66-.12,1.206-.36,1.639-.24.432-.561.751-.963.958s-.846.311-1.332.311Zm-1.898,6.579l8.234-12.078h2.862l-8.208,12.078h-2.889Zm1.898-8.343c.174,0,.313-.078.419-.234.104-.156.157-.459.157-.909,0-.426-.053-.712-.157-.859-.105-.146-.245-.221-.419-.221-.168,0-.3.074-.396.221-.097.147-.145.434-.145.859,0,.45.048.753.145.909.096.156.228.234.396.234Zm7.335,8.559c-.468,0-.901-.104-1.3-.311-.399-.207-.719-.524-.959-.954-.24-.429-.36-.97-.36-1.624,0-.666.12-1.214.36-1.643s.56-.747.959-.954c.398-.207.832-.311,1.3-.311.475,0,.912.104,1.314.311.401.207.726.525.972.954s.369.977.369,1.643c0,.654-.123,1.195-.369,1.624-.246.43-.57.747-.972.954-.402.207-.84.311-1.314.311Zm0-1.782c.168,0,.303-.074.405-.225.102-.149.153-.444.153-.882s-.052-.736-.153-.896c-.103-.159-.237-.238-.405-.238s-.301.079-.4.238c-.099.159-.148.457-.148.896s.05.732.148.882c.1.15.232.225.4.225Z"
                    fill="#fff"
                  />
                  <path
                    d="m1.755,119.562v-11.466h7.083v1.476H3.465v3.475h4.968v1.467H3.465v5.049h-1.71Z"
                    fill="#fff"
                  />
                  <path
                    d="m13.887,119.796c-.727,0-1.329-.128-1.81-.383-.479-.255-.838-.59-1.075-1.004s-.355-.854-.355-1.322c0-.57.146-1.04.437-1.409s.694-.644,1.211-.823c.516-.18,1.109-.27,1.781-.27.462,0,.912.042,1.351.126.438.084.87.194,1.296.333v1.034c-.349-.126-.733-.226-1.156-.301-.424-.075-.818-.113-1.184-.113-.546,0-1.002.108-1.368.324s-.549.582-.549,1.099c0,.378.144.703.432.977.288.272.744.409,1.368.409.456,0,.857-.104,1.206-.311.348-.207.618-.467.81-.778.192-.312.288-.624.288-.937h.414c0,.601-.121,1.153-.364,1.661-.243.507-.596.915-1.058,1.224s-1.021.464-1.674.464Zm2.897-.234l-.216-1.989v-3.608c0-.708-.146-1.223-.44-1.544-.295-.321-.76-.481-1.396-.481-.36,0-.68.053-.958.157-.279.105-.498.263-.657.473s-.238.475-.238.792h-1.891c0-.636.156-1.151.468-1.548.312-.396.749-.686,1.31-.868.562-.184,1.217-.274,1.967-.274.702,0,1.32.115,1.854.346.533.231.949.593,1.246,1.085s.445,1.128.445,1.908v5.553h-1.494Z"
                    fill="#fff"
                  />
                  <path
                    d="m21.527,119.562v-12.303h1.71v12.303h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m32.544,119.562v-8.685h1.71v8.685h-1.71Zm1.242-3.87c0-1.109.115-2.032.347-2.768.23-.734.555-1.282.972-1.643.417-.359.907-.54,1.472-.54.168,0,.325.013.473.036.146.024.292.058.437.1l-.063,1.7c-.162-.042-.337-.079-.526-.112-.188-.033-.361-.05-.518-.05-.492,0-.894.131-1.206.392-.312.262-.543.637-.692,1.125-.15.489-.226,1.076-.226,1.76h-.468Z"
                    fill="#fff"
                  />
                  <path
                    d="m38.123,112.065v-1.161h5.589v1.161h-5.589Zm3.942,7.731c-.492,0-.937-.094-1.332-.279-.396-.186-.71-.467-.94-.842-.231-.375-.347-.848-.347-1.417v-8.712h1.701v8.657c0,.816.381,1.225,1.143,1.225.234,0,.462-.025.685-.077.222-.051.474-.127.756-.229l.243,1.216c-.318.132-.632.241-.94.328-.31.087-.632.131-.968.131Z"
                    fill="#fff"
                  />
                  <path
                    d="m46.196,119.562v-12.303h1.71v12.303h-1.71Zm1.467-5.139l4.113-3.52h2.349l-6.462,5.311v-1.791Zm4.581,5.139l-4.014-5.49,1.674-.351,4.428,5.841h-2.088Z"
                    fill="#fff"
                  />
                  <path
                    d="m59.812,119.796c-.786,0-1.489-.171-2.11-.514-.621-.342-1.111-.852-1.472-1.529-.359-.679-.54-1.516-.54-2.512,0-1.014.181-1.858.54-2.533.36-.675.851-1.184,1.472-1.525s1.324-.513,2.11-.513c.774,0,1.471.171,2.088.513.618.342,1.105.851,1.463,1.525.356.675.535,1.52.535,2.533,0,.996-.179,1.833-.535,2.512-.357.678-.845,1.188-1.463,1.529-.617.343-1.313.514-2.088.514Zm-.054-1.494c.498,0,.929-.12,1.292-.36.362-.24.642-.59.837-1.049.194-.459.292-1.009.292-1.651,0-.983-.199-1.746-.599-2.286-.398-.54-.974-.81-1.724-.81-.504,0-.938.121-1.3.364-.363.243-.643.595-.837,1.054-.195.459-.293,1.019-.293,1.678,0,.967.201,1.718.604,2.255.401.537.978.806,1.728.806Z"
                    fill="#fff"
                  />
                  <path
                    d="m69.713,119.796c-.84,0-1.548-.119-2.124-.355-.576-.237-1.016-.567-1.318-.99s-.461-.92-.473-1.489h1.611c.023.498.224.866.599,1.106s.883.36,1.525.36c.342,0,.652-.055.932-.162.278-.108.501-.255.666-.441.165-.186.247-.396.247-.63,0-.294-.107-.526-.324-.697-.216-.171-.563-.322-1.044-.454l-1.772-.604c-.708-.24-1.251-.56-1.629-.958-.378-.399-.567-.878-.567-1.436,0-.714.318-1.289.954-1.724.636-.436,1.479-.652,2.529-.652,1.086,0,1.924.229,2.515.688.592.459.899,1.033.923,1.723h-1.646c-.042-.342-.212-.604-.509-.787-.297-.183-.725-.274-1.282-.274-.522,0-.932.114-1.229.342-.297.229-.446.486-.446.774,0,.516.444.899,1.332,1.151l1.863.657c.708.229,1.254.522,1.638.882.385.36.576.816.576,1.368,0,.444-.146.864-.44,1.26-.294.396-.707.719-1.238.968-.53.249-1.153.374-1.867.374Z"
                    fill="#fff"
                  />
                  <path
                    d="m74.86,112.065v-1.161h5.589v1.161h-5.589Zm3.942,7.731c-.492,0-.937-.094-1.332-.279-.396-.186-.71-.467-.94-.842-.231-.375-.347-.848-.347-1.417v-8.712h1.701v8.657c0,.816.381,1.225,1.143,1.225.234,0,.462-.025.685-.077.222-.051.474-.127.756-.229l.243,1.216c-.318.132-.632.241-.94.328-.31.087-.632.131-.968.131Z"
                    fill="#fff"
                  />
                  <path
                    d="m86.039,119.796c-.744,0-1.416-.192-2.017-.576-.6-.384-1.075-.918-1.426-1.603-.352-.684-.527-1.476-.527-2.376,0-.954.175-1.771.522-2.452s.822-1.204,1.422-1.57,1.275-.549,2.025-.549c.672,0,1.247.136,1.728.409s.867.639,1.161,1.098.496.979.607,1.562.131,1.183.059,1.8h-6.156v-.989h4.986l-.414.27c.018-.324,0-.647-.05-.972s-.148-.623-.292-.896c-.145-.273-.347-.492-.607-.657-.262-.165-.593-.247-.995-.247-.504,0-.926.126-1.265.378s-.595.589-.77,1.013c-.174.423-.261.886-.261,1.39v.693c0,.558.084,1.053.252,1.485.168.432.419.772.752,1.021s.754.373,1.265.373c.498,0,.91-.117,1.237-.351.327-.234.529-.585.607-1.054h1.746c-.09.564-.297,1.057-.621,1.477s-.739.745-1.246.977c-.508.23-1.082.347-1.724.347Z"
                    fill="#fff"
                  />
                  <path
                    d="m92.303,119.562v-8.658h1.485l.225,1.872v6.786h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m106.162,120.921l4.562-14.625h1.746l-4.59,14.625h-1.719Z"
                    fill="#fff"
                  />
                  <path
                    d="m5.823,143.796c-1.015,0-1.889-.248-2.624-.743-.734-.495-1.304-1.193-1.705-2.097-.402-.903-.604-1.964-.604-3.182,0-1.284.213-2.362.64-3.235.426-.873,1.013-1.533,1.759-1.98.747-.446,1.592-.67,2.534-.67,1.266,0,2.273.313,3.023.94s1.248,1.441,1.494,2.443l-1.827.351c-.216-.665-.558-1.198-1.025-1.597-.469-.399-1.023-.599-1.665-.599-.564,0-1.08.162-1.548.485-.469.324-.844.809-1.125,1.454-.282.645-.424,1.447-.424,2.407,0,.888.117,1.667.352,2.336.233.669.579,1.189,1.035,1.562s1.025.558,1.71.558c.834,0,1.49-.224,1.971-.671.48-.446.72-1.03.72-1.75h1.846c0,.84-.188,1.556-.562,2.146s-.902,1.046-1.58,1.363c-.678.318-1.476.478-2.394.478Z"
                    fill="#fff"
                  />
                  <path
                    d="m12.941,143.562v-8.685h1.71v8.685h-1.71Zm1.242-3.87c0-1.109.115-2.032.347-2.768.23-.734.555-1.282.972-1.643.417-.359.907-.54,1.472-.54.168,0,.325.013.473.036.146.024.292.058.437.1l-.063,1.7c-.162-.042-.337-.079-.526-.112-.188-.033-.361-.05-.518-.05-.492,0-.894.131-1.206.392-.312.262-.543.637-.692,1.125-.15.489-.226,1.076-.226,1.76h-.468Z"
                    fill="#fff"
                  />
                  <path
                    d="m22.788,143.796c-.744,0-1.416-.192-2.017-.576-.6-.384-1.075-.918-1.426-1.603-.352-.684-.527-1.476-.527-2.376,0-.954.175-1.771.522-2.452s.822-1.204,1.422-1.57,1.275-.549,2.025-.549c.672,0,1.247.136,1.728.409s.867.639,1.161,1.098.496.979.607,1.562.131,1.183.059,1.8h-6.156v-.989h4.986l-.414.27c.018-.324,0-.647-.05-.972s-.148-.623-.292-.896c-.145-.273-.347-.492-.607-.657-.262-.165-.593-.247-.995-.247-.504,0-.926.126-1.265.378s-.595.589-.77,1.013c-.174.423-.261.886-.261,1.39v.693c0,.558.084,1.053.252,1.485.168.432.419.772.752,1.021s.754.373,1.265.373c.498,0,.91-.117,1.237-.351.327-.234.529-.585.607-1.054h1.746c-.09.564-.297,1.057-.621,1.477s-.739.745-1.246.977c-.508.23-1.082.347-1.724.347Z"
                    fill="#fff"
                  />
                  <path
                    d="m27.576,134.904h1.781l2.062,6.948h-.189l1.989-6.507h1.485l1.979,6.524h-.18l2.061-6.966h1.71l-2.844,8.658h-1.674l-1.962-6.12h.261l-1.935,6.12h-1.674l-2.871-8.658Z"
                    fill="#fff"
                  />
                  <path
                    d="m50.094,141.717l3.194-9.621h1.81l-4.023,11.466h-1.791l-3.978-11.466h1.817l3.168,9.621h-.197Z"
                    fill="#fff"
                  />
                  <path
                    d="m60.272,143.796c-.744,0-1.416-.192-2.017-.576-.6-.384-1.075-.918-1.426-1.603-.352-.684-.527-1.476-.527-2.376,0-.954.175-1.771.522-2.452s.822-1.204,1.422-1.57,1.275-.549,2.025-.549c.672,0,1.247.136,1.728.409s.867.639,1.161,1.098.496.979.607,1.562.131,1.183.059,1.8h-6.156v-.989h4.986l-.414.27c.018-.324,0-.647-.05-.972s-.148-.623-.292-.896c-.145-.273-.347-.492-.607-.657-.262-.165-.593-.247-.995-.247-.504,0-.926.126-1.265.378s-.595.589-.77,1.013c-.174.423-.261.886-.261,1.39v.693c0,.558.084,1.053.252,1.485.168.432.419.772.752,1.021s.754.373,1.265.373c.498,0,.91-.117,1.237-.351.327-.234.529-.585.607-1.054h1.746c-.09.564-.297,1.057-.621,1.477s-.739.745-1.246.977c-.508.23-1.082.347-1.724.347Z"
                    fill="#fff"
                  />
                  <path
                    d="m66.536,143.562v-8.685h1.71v8.685h-1.71Zm1.242-3.87c0-1.109.115-2.032.347-2.768.23-.734.555-1.282.972-1.643.417-.359.907-.54,1.472-.54.168,0,.325.013.473.036.146.024.292.058.437.1l-.063,1.7c-.162-.042-.337-.079-.526-.112-.188-.033-.361-.05-.518-.05-.492,0-.894.131-1.206.392-.312.262-.543.637-.692,1.125-.15.489-.226,1.076-.226,1.76h-.468Z"
                    fill="#fff"
                  />
                  <path
                    d="m73.277,147.81v-12.906h1.413l.297,2.079v10.827h-1.71Zm4.104-4.014c-.631,0-1.177-.143-1.639-.428s-.814-.705-1.058-1.26c-.242-.556-.364-1.244-.364-2.065h.666c0,.474.103.874.307,1.201.203.327.472.576.805.747s.688.256,1.066.256c.396,0,.782-.112,1.157-.337.375-.226.683-.562.922-1.013.24-.45.36-1.008.36-1.674,0-.636-.106-1.179-.319-1.629s-.503-.795-.869-1.035c-.365-.24-.776-.36-1.232-.36-.384,0-.744.092-1.08.274-.336.184-.606.45-.81.802-.204.351-.307.778-.307,1.282h-.666c0-.87.14-1.593.419-2.169.278-.576.655-1.007,1.129-1.292.475-.284.999-.427,1.575-.427.678,0,1.314.181,1.908.544s1.075.884,1.444,1.562c.369.679.554,1.494.554,2.448s-.187,1.772-.559,2.457c-.372.684-.859,1.207-1.462,1.57-.604.363-1.253.545-1.948.545Z"
                    fill="#fff"
                  />
                  <path
                    d="m83.123,136.065v-1.161h4.779v1.161h-4.779Zm1.359,7.497v-9.873c0-.6.115-1.088.347-1.463.23-.374.538-.652.922-.832s.808-.271,1.27-.271c.264,0,.535.019.814.055.278.036.554.09.823.162l-.324,1.332c-.168-.061-.337-.108-.508-.145-.172-.036-.335-.054-.491-.054-.186,0-.366.034-.54.104s-.316.193-.427.373c-.111.181-.167.436-.167.766v9.846h-1.719Zm5.868,0v-12.303h1.71v12.303h-1.71Z"
                    fill="#fff"
                  />
                  <path
                    d="m98.685,143.796c-.744,0-1.416-.192-2.017-.576-.6-.384-1.075-.918-1.426-1.603-.352-.684-.527-1.476-.527-2.376,0-.954.175-1.771.522-2.452s.822-1.204,1.422-1.57,1.275-.549,2.025-.549c.672,0,1.247.136,1.728.409s.867.639,1.161,1.098.496.979.607,1.562.131,1.183.059,1.8h-6.156v-.989h4.986l-.414.27c.018-.324,0-.647-.05-.972s-.148-.623-.292-.896c-.145-.273-.347-.492-.607-.657-.262-.165-.593-.247-.995-.247-.504,0-.926.126-1.265.378s-.595.589-.77,1.013c-.174.423-.261.886-.261,1.39v.693c0,.558.084,1.053.252,1.485.168.432.419.772.752,1.021s.754.373,1.265.373c.498,0,.91-.117,1.237-.351.327-.234.529-.585.607-1.054h1.746c-.09.564-.297,1.057-.621,1.477s-.739.745-1.246.977c-.508.23-1.082.347-1.724.347Z"
                    fill="#fff"
                  />
                  <path
                    d="m108.134,148.026c-.937,0-1.742-.129-2.417-.387s-1.195-.604-1.562-1.035-.549-.903-.549-1.413c0-.647.237-1.145.711-1.489s1.077-.518,1.809-.518l1.107.521c-.624,0-1.105.126-1.444.379-.339.252-.509.594-.509,1.025,0,.45.246.82.738,1.111s1.197.437,2.115.437c.666,0,1.251-.098,1.755-.292.504-.195.894-.45,1.17-.766.275-.314.414-.652.414-1.012,0-.246-.089-.455-.266-.626s-.445-.257-.806-.257h-3.168c-.588,0-1.105-.091-1.553-.274-.447-.183-.796-.424-1.048-.725-.252-.3-.379-.633-.379-.998,0-.36.103-.671.307-.932.204-.262.474-.459.81-.595.336-.135.705-.202,1.107-.202l.387.594c-.174,0-.348.038-.521.112-.175.075-.318.188-.433.338-.114.15-.171.336-.171.558,0,.259.109.473.329.644.219.172.607.257,1.165.257h3.168c.864,0,1.542.171,2.034.513.492.343.738.841.738,1.494,0,.595-.204,1.161-.612,1.701s-.988.981-1.741,1.323c-.754.342-1.649.513-2.687.513Zm.188-7.064c-.672,0-1.282-.131-1.831-.392-.55-.262-.985-.627-1.31-1.099-.324-.471-.486-1.021-.486-1.651,0-.636.162-1.189.486-1.66s.76-.837,1.31-1.099c.549-.261,1.159-.391,1.831-.391.654,0,1.253.13,1.796.391.543.262.978.628,1.305,1.099s.49,1.024.49,1.66c0,.63-.163,1.181-.49,1.651-.327.472-.762.837-1.305,1.099-.543.261-1.142.392-1.796.392Zm0-1.206c.618,0,1.091-.185,1.418-.554.326-.369.49-.829.49-1.382,0-.576-.164-1.041-.49-1.395-.327-.354-.8-.531-1.418-.531-.606,0-1.078.177-1.417.531-.34.354-.509.818-.509,1.395,0,.553.169,1.013.509,1.382.339.369.811.554,1.417.554Zm2.943-3.699l-.801-.387c0-.517.107-.967.323-1.351s.517-.684.9-.899c.384-.217.825-.324,1.323-.324l-.108,1.665c-.534,0-.94.104-1.22.314-.278.21-.418.537-.418.981Z"
                    fill="#fff"
                  />
                  <path
                    d="m115.027,134.877h1.71v5.363c0,.654.16,1.149.481,1.485s.772.504,1.354.504c.492,0,.926-.129,1.301-.387s.669-.624.882-1.099c.213-.474.319-1.034.319-1.683h.549c0,1.014-.138,1.866-.414,2.556-.275.69-.663,1.214-1.161,1.571-.498.356-1.079.535-1.745.535-.637,0-1.202-.117-1.697-.352-.495-.233-.882-.595-1.161-1.084-.278-.489-.418-1.12-.418-1.895v-5.517Zm6.048,0h1.719v8.685h-1.719v-8.685Z"
                    fill="#fff"
                  />
                  <path
                    d="m126.304,143.562v-8.658h1.485l.225,1.872v6.786h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m140.507,148.026c-.937,0-1.742-.129-2.417-.387s-1.195-.604-1.562-1.035-.549-.903-.549-1.413c0-.647.237-1.145.711-1.489s1.077-.518,1.809-.518l1.107.521c-.624,0-1.105.126-1.444.379-.339.252-.509.594-.509,1.025,0,.45.246.82.738,1.111s1.197.437,2.115.437c.666,0,1.251-.098,1.755-.292.504-.195.894-.45,1.17-.766.275-.314.414-.652.414-1.012,0-.246-.089-.455-.266-.626s-.445-.257-.806-.257h-3.168c-.588,0-1.105-.091-1.553-.274-.447-.183-.796-.424-1.048-.725-.252-.3-.379-.633-.379-.998,0-.36.103-.671.307-.932.204-.262.474-.459.81-.595.336-.135.705-.202,1.107-.202l.387.594c-.174,0-.348.038-.521.112-.175.075-.318.188-.433.338-.114.15-.171.336-.171.558,0,.259.109.473.329.644.219.172.607.257,1.165.257h3.168c.864,0,1.542.171,2.034.513.492.343.738.841.738,1.494,0,.595-.204,1.161-.612,1.701s-.988.981-1.741,1.323c-.754.342-1.649.513-2.687.513Zm.188-7.064c-.672,0-1.282-.131-1.831-.392-.55-.262-.985-.627-1.31-1.099-.324-.471-.486-1.021-.486-1.651,0-.636.162-1.189.486-1.66s.76-.837,1.31-1.099c.549-.261,1.159-.391,1.831-.391.654,0,1.253.13,1.796.391.543.262.978.628,1.305,1.099s.49,1.024.49,1.66c0,.63-.163,1.181-.49,1.651-.327.472-.762.837-1.305,1.099-.543.261-1.142.392-1.796.392Zm0-1.206c.618,0,1.091-.185,1.418-.554.326-.369.49-.829.49-1.382,0-.576-.164-1.041-.49-1.395-.327-.354-.8-.531-1.418-.531-.606,0-1.078.177-1.417.531-.34.354-.509.818-.509,1.395,0,.553.169,1.013.509,1.382.339.369.811.554,1.417.554Zm2.943-3.699l-.801-.387c0-.517.107-.967.323-1.351s.517-.684.9-.899c.384-.217.825-.324,1.323-.324l-.108,1.665c-.534,0-.94.104-1.22.314-.278.21-.418.537-.418.981Z"
                    fill="#fff"
                  />
                </g>
                <g id="_8_Deko_Print" data-name="8 % Deko / Print">
                  <path
                    d="m3.285,170.475h2.088v12.186h-2.88v-8.721c-.342.084-.662.149-.959.197-.297.049-.596.069-.896.063v-2.736c.438-.036.866-.129,1.283-.278.417-.15.871-.388,1.363-.711Z"
                    fill="#fff"
                  />
                  <path
                    d="m13.266,170.493h2.826v7.263h2.106v2.484h-2.106v2.421h-2.861v-2.421h-5.994v-2.151l6.029-7.596Zm.127,4.131l-2.755,3.465h2.755v-3.465Z"
                    fill="#fff"
                  />
                  <path
                    d="m25.632,176.082c-.468,0-.901-.104-1.301-.311-.398-.207-.718-.526-.958-.958-.24-.433-.36-.979-.36-1.639,0-.647.12-1.184.36-1.606s.56-.737.958-.944c.399-.207.833-.311,1.301-.311.486,0,.93.104,1.332.311s.723.521.963.944.36.959.36,1.606c0,.66-.12,1.206-.36,1.639-.24.432-.561.751-.963.958s-.846.311-1.332.311Zm-1.898,6.579l8.234-12.078h2.862l-8.208,12.078h-2.889Zm1.898-8.343c.174,0,.313-.078.419-.234.104-.156.157-.459.157-.909,0-.426-.053-.712-.157-.859-.105-.146-.245-.221-.419-.221-.168,0-.3.074-.396.221-.097.147-.145.434-.145.859,0,.45.048.753.145.909.096.156.228.234.396.234Zm7.335,8.559c-.468,0-.901-.104-1.3-.311-.399-.207-.719-.524-.959-.954-.24-.429-.36-.97-.36-1.624,0-.666.12-1.214.36-1.643s.56-.747.959-.954c.398-.207.832-.311,1.3-.311.475,0,.912.104,1.314.311.401.207.726.525.972.954s.369.977.369,1.643c0,.654-.123,1.195-.369,1.624-.246.43-.57.747-.972.954-.402.207-.84.311-1.314.311Zm0-1.782c.168,0,.303-.074.405-.225.102-.149.153-.444.153-.882s-.052-.736-.153-.896c-.103-.159-.237-.238-.405-.238s-.301.079-.4.238c-.099.159-.148.457-.148.896s.05.732.148.882c.1.15.232.225.4.225Z"
                    fill="#fff"
                  />
                  <path
                    d="m1.755,203.195h3.51c1.164,0,2.177.235,3.038.706.86.472,1.531,1.136,2.012,1.994.479.857.72,1.869.72,3.032s-.24,2.166-.72,3.024c-.48.858-1.151,1.524-2.012,1.998-.861.474-1.874.711-3.038.711H1.755v-11.466Zm1.71,1.539v8.415h1.8c.816,0,1.517-.184,2.102-.549.585-.366,1.037-.867,1.354-1.504.318-.636.478-1.358.478-2.169s-.159-1.531-.478-2.164c-.317-.633-.77-1.13-1.354-1.489-.585-.36-1.285-.54-2.102-.54h-1.8Z"
                    fill="#fff"
                  />
                  <path
                    d="m16.947,214.895c-.744,0-1.416-.192-2.017-.576-.6-.384-1.075-.918-1.426-1.603-.352-.684-.527-1.476-.527-2.376,0-.954.175-1.771.522-2.452s.822-1.204,1.422-1.57,1.275-.549,2.025-.549c.672,0,1.247.136,1.728.409s.867.639,1.161,1.098.496.979.607,1.562.131,1.183.059,1.8h-6.156v-.989h4.986l-.414.27c.018-.324,0-.647-.05-.972s-.148-.623-.292-.896c-.145-.273-.347-.492-.607-.657-.262-.165-.593-.247-.995-.247-.504,0-.926.126-1.265.378s-.595.589-.77,1.013c-.174.423-.261.886-.261,1.39v.693c0,.558.084,1.053.252,1.485.168.432.419.772.752,1.021s.754.373,1.265.373c.498,0,.91-.117,1.237-.351.327-.234.529-.585.607-1.054h1.746c-.09.564-.297,1.057-.621,1.477s-.739.745-1.246.977c-.508.23-1.082.347-1.724.347Z"
                    fill="#fff"
                  />
                  <path
                    d="m23.211,214.661v-12.303h1.71v12.303h-1.71Zm1.467-5.139l4.113-3.52h2.349l-6.462,5.311v-1.791Zm4.581,5.139l-4.014-5.49,1.674-.351,4.428,5.841h-2.088Z"
                    fill="#fff"
                  />
                  <path
                    d="m36.827,214.895c-.786,0-1.489-.171-2.11-.514-.621-.342-1.111-.852-1.472-1.529-.359-.679-.54-1.516-.54-2.512,0-1.014.181-1.858.54-2.533.36-.675.851-1.184,1.472-1.525s1.324-.513,2.11-.513c.774,0,1.471.171,2.088.513.618.342,1.105.851,1.463,1.525.356.675.535,1.52.535,2.533,0,.996-.179,1.833-.535,2.512-.357.678-.845,1.188-1.463,1.529-.617.343-1.313.514-2.088.514Zm-.054-1.494c.498,0,.929-.12,1.292-.36.362-.24.642-.59.837-1.049.194-.459.292-1.009.292-1.651,0-.983-.199-1.746-.599-2.286-.398-.54-.974-.81-1.724-.81-.504,0-.938.121-1.3.364-.363.243-.643.595-.837,1.054-.195.459-.293,1.019-.293,1.678,0,.967.201,1.718.604,2.255.401.537.978.806,1.728.806Z"
                    fill="#fff"
                  />
                  <path
                    d="m46.403,216.02l4.562-14.625h1.746l-4.59,14.625h-1.719Z"
                    fill="#fff"
                  />
                  <path
                    d="m58.787,214.661v-11.466h3.843c1.278,0,2.263.318,2.952.954.69.636,1.035,1.515,1.035,2.637,0,1.11-.345,1.981-1.035,2.614-.689.634-1.674.95-2.952.95h-2.114v4.311h-1.729Zm1.729-5.805h2.114c.732,0,1.287-.189,1.665-.567s.567-.879.567-1.503c0-.63-.189-1.14-.567-1.53-.378-.39-.933-.585-1.665-.585h-2.114v4.186Z"
                    fill="#fff"
                  />
                  <path
                    d="m69.002,214.661v-8.685h1.71v8.685h-1.71Zm1.242-3.87c0-1.109.115-2.032.347-2.768.23-.734.555-1.282.972-1.643.417-.359.907-.54,1.472-.54.168,0,.325.013.473.036.146.024.292.058.437.1l-.063,1.7c-.162-.042-.337-.079-.526-.112-.188-.033-.361-.05-.518-.05-.492,0-.894.131-1.206.392-.312.262-.543.637-.692,1.125-.15.489-.226,1.076-.226,1.76h-.468Z"
                    fill="#fff"
                  />
                  <path
                    d="m76.733,204.158c-.342,0-.61-.095-.806-.283-.195-.189-.292-.446-.292-.77,0-.312.097-.56.292-.743.195-.183.464-.274.806-.274.336,0,.602.092.796.274.195.184.293.431.293.743,0,.323-.098.58-.293.77-.194.188-.46.283-.796.283Zm-.855,10.503v-8.685h1.71v8.685h-1.71Z"
                    fill="#fff"
                  />
                  <path
                    d="m81.341,214.661v-8.658h1.485l.225,1.872v6.786h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m91.195,207.164v-1.161h5.589v1.161h-5.589Zm3.942,7.731c-.492,0-.937-.094-1.332-.279-.396-.186-.71-.467-.94-.842-.231-.375-.347-.848-.347-1.417v-8.712h1.701v8.657c0,.816.381,1.225,1.143,1.225.234,0,.462-.025.685-.077.222-.051.474-.127.756-.229l.243,1.216c-.318.132-.632.241-.94.328-.31.087-.632.131-.968.131Z"
                    fill="#fff"
                  />
                </g>
                <g
                  id="_13_Technik_Video_Licht_Sound_"
                  data-name="13 %  Technik (Video / Licht / Sound)"
                >
                  <path
                    d="m1.008,263.619v-2.34c0-.654.09-1.22.271-1.696.18-.478.471-.887.873-1.229.401-.343.933-.637,1.593-.883l2.564-.953c.462-.181.798-.394,1.009-.64.21-.246.314-.543.314-.891,0-.318-.126-.602-.378-.851s-.624-.374-1.116-.374c-.456,0-.849.139-1.179.414-.33.276-.519.67-.567,1.18H1.206c-.006-.948.215-1.724.661-2.327.447-.603,1.043-1.047,1.787-1.332.743-.284,1.554-.427,2.43-.427.852,0,1.628.155,2.326.468.699.312,1.257.751,1.675,1.318.416.567.625,1.24.625,2.021,0,.618-.111,1.167-.333,1.647-.222.479-.579.907-1.071,1.282s-1.146.715-1.962,1.021l-2.547.963c-.348.132-.586.26-.716.383-.129.123-.193.283-.193.481v.135h6.786v2.628H1.008Z"
                    fill="#fff"
                  />
                  <path
                    d="m14.661,251.433h2.088v12.186h-2.88v-8.721c-.342.084-.662.149-.959.197-.297.049-.596.069-.896.063v-2.736c.438-.036.866-.129,1.283-.278.417-.15.871-.388,1.363-.711Z"
                    fill="#fff"
                  />
                  <path
                    d="m25.604,257.04c-.468,0-.901-.104-1.301-.311-.398-.207-.718-.526-.958-.958-.24-.433-.36-.979-.36-1.639,0-.647.12-1.184.36-1.606s.56-.737.958-.944c.399-.207.833-.311,1.301-.311.486,0,.93.104,1.332.311s.723.521.963.944.36.959.36,1.606c0,.66-.12,1.206-.36,1.639-.24.432-.561.751-.963.958s-.846.311-1.332.311Zm-1.898,6.579l8.234-12.078h2.862l-8.208,12.078h-2.889Zm1.898-8.343c.174,0,.313-.078.419-.234.104-.156.157-.459.157-.909,0-.426-.053-.712-.157-.859-.105-.146-.245-.221-.419-.221-.168,0-.3.074-.396.221-.097.147-.145.434-.145.859,0,.45.048.753.145.909.096.156.228.234.396.234Zm7.335,8.559c-.468,0-.901-.104-1.3-.311-.399-.207-.719-.524-.959-.954-.24-.429-.36-.97-.36-1.624,0-.666.12-1.214.36-1.643s.56-.747.959-.954c.398-.207.832-.311,1.3-.311.475,0,.912.104,1.314.311.401.207.726.525.972.954s.369.977.369,1.643c0,.654-.123,1.195-.369,1.624-.246.43-.57.747-.972.954-.402.207-.84.311-1.314.311Zm0-1.782c.168,0,.303-.074.405-.225.102-.149.153-.444.153-.882s-.052-.736-.153-.896c-.103-.159-.237-.238-.405-.238s-.301.079-.4.238c-.099.159-.148.457-.148.896s.05.732.148.882c.1.15.232.225.4.225Z"
                    fill="#fff"
                  />
                  <path
                    d="m.108,285.628v-1.476h9.035v1.476h-4.05l.388-.396v10.386h-1.71v-10.386l.378.396H.108Z"
                    fill="#fff"
                  />
                  <path
                    d="m14.104,295.853c-.744,0-1.416-.192-2.017-.576-.6-.384-1.075-.918-1.426-1.603-.352-.684-.527-1.476-.527-2.376,0-.954.175-1.771.522-2.452s.822-1.204,1.422-1.57,1.275-.549,2.025-.549c.672,0,1.247.136,1.728.409s.867.639,1.161,1.098.496.979.607,1.562.131,1.183.059,1.8h-6.156v-.989h4.986l-.414.27c.018-.324,0-.647-.05-.972s-.148-.623-.292-.896c-.145-.273-.347-.492-.607-.657-.262-.165-.593-.247-.995-.247-.504,0-.926.126-1.265.378s-.595.589-.77,1.013c-.174.423-.261.886-.261,1.39v.693c0,.558.084,1.053.252,1.485.168.432.419.772.752,1.021s.754.373,1.265.373c.498,0,.91-.117,1.237-.351.327-.234.529-.585.607-1.054h1.746c-.09.564-.297,1.057-.621,1.477s-.739.745-1.246.977c-.508.23-1.082.347-1.724.347Z"
                    fill="#fff"
                  />
                  <path
                    d="m23.517,295.853c-.708,0-1.368-.189-1.98-.567-.611-.378-1.104-.909-1.476-1.593-.372-.685-.559-1.488-.559-2.412,0-.972.187-1.796.559-2.471s.864-1.19,1.476-1.548c.612-.357,1.272-.535,1.98-.535.521,0,.986.082,1.395.247s.759.387,1.053.666.533.593.716.94.311.708.383,1.08l-1.737.09c-.126-.45-.347-.812-.661-1.084-.315-.273-.704-.41-1.166-.41-.39,0-.757.109-1.103.329-.345.219-.624.552-.837.999-.213.446-.319,1.012-.319,1.696,0,.636.099,1.179.297,1.629s.468.795.811,1.035c.342.24.726.359,1.151.359.612,0,1.069-.148,1.373-.445.303-.297.454-.706.454-1.229h1.737c0,.606-.142,1.152-.423,1.639-.282.485-.688.871-1.22,1.156s-1.166.428-1.903.428Z"
                    fill="#fff"
                  />
                  <path
                    d="m29.754,295.619v-12.303h1.71v12.303h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m40.77,295.619v-8.658h1.485l.225,1.872v6.786h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m52.775,285.116c-.342,0-.61-.095-.806-.283-.195-.189-.292-.446-.292-.77,0-.312.097-.56.292-.743.195-.183.464-.274.806-.274.336,0,.602.092.796.274.195.184.293.431.293.743,0,.323-.098.58-.293.77-.194.188-.46.283-.796.283Zm-.855,10.503v-8.685h1.71v8.685h-1.71Z"
                    fill="#fff"
                  />
                  <path
                    d="m57.384,295.619v-12.303h1.71v12.303h-1.71Zm1.467-5.139l4.113-3.52h2.349l-6.462,5.311v-1.791Zm4.581,5.139l-4.014-5.49,1.674-.351,4.428,5.841h-2.088Z"
                    fill="#fff"
                  />
                  <path
                    d="m3.857,303.28l.959.686c-.532.546-.977,1.105-1.334,1.677-.356.571-.625,1.158-.805,1.761-.18.602-.27,1.221-.27,1.854,0,.943.2,1.852.602,2.727s1.004,1.724,1.807,2.545l-.959.707c-.948-.859-1.657-1.797-2.129-2.814-.471-1.017-.706-2.071-.706-3.164,0-1.105.235-2.166.706-3.182.472-1.015,1.181-1.946,2.129-2.796Z"
                    fill="#fff"
                  />
                  <path
                    d="m9.212,312.184l2.485-7.483h1.407l-3.129,8.918h-1.394l-3.094-8.918h1.414l2.464,7.483h-.154Z"
                    fill="#fff"
                  />
                  <path
                    d="m15.483,305.45c-.266,0-.475-.073-.626-.221-.152-.146-.228-.347-.228-.599,0-.242.075-.435.228-.577.151-.143.36-.214.626-.214.262,0,.468.071.62.214.151.143.228.335.228.577,0,.252-.076.452-.228.599-.152.147-.358.221-.62.221Zm-.665,8.169v-6.755h1.33v6.755h-1.33Z"
                    fill="#fff"
                  />
                  <path
                    d="m21.511,313.8c-.551,0-1.057-.147-1.519-.444-.462-.296-.829-.711-1.103-1.246-.273-.534-.409-1.163-.409-1.886,0-.719.134-1.342.402-1.869s.624-.935,1.067-1.222.936-.431,1.477-.431c.495,0,.927.12,1.295.36.369.24.655.59.858,1.047.202.457.304,1.015.304,1.673h-.462c0-.406-.081-.75-.245-1.032-.163-.282-.375-.496-.637-.641s-.541-.217-.84-.217c-.35,0-.666.094-.948.283-.283.188-.507.457-.672.805-.166.348-.249.762-.249,1.243,0,.485.083.906.249,1.263.165.357.389.632.672.823.282.191.596.286.941.286.284,0,.559-.075.822-.227.264-.152.48-.37.651-.655.17-.284.255-.623.255-1.015h.462c0,.672-.105,1.239-.318,1.701-.212.462-.498.811-.857,1.046-.359.236-.758.354-1.197.354Zm2.143-.182l-.231-1.617v-7.952h1.337v9.569h-1.105Z"
                    fill="#fff"
                  />
                  <path
                    d="m29.903,313.8c-.579,0-1.102-.149-1.568-.447-.467-.299-.836-.715-1.109-1.246-.273-.532-.409-1.148-.409-1.849,0-.741.135-1.378.405-1.907s.64-.937,1.106-1.222c.467-.284.991-.427,1.575-.427.522,0,.971.106,1.344.318.373.213.674.497.903.854.229.356.386.762.472,1.214.087.453.102.92.046,1.4h-4.788v-.771h3.878l-.321.21c.014-.252,0-.504-.039-.756s-.115-.483-.228-.696c-.112-.212-.27-.383-.473-.511-.203-.129-.461-.192-.773-.192-.392,0-.72.098-.983.294-.264.195-.463.458-.599.787-.135.329-.203.689-.203,1.082v.539c0,.434.065.818.196,1.154s.325.602.585.795c.259.193.586.29.983.29.387,0,.708-.091.962-.272.255-.183.412-.455.473-.819h1.358c-.07.438-.231.821-.483,1.148-.252.326-.575.579-.97.759-.394.18-.841.27-1.34.27Z"
                    fill="#fff"
                  />
                  <path
                    d="m37.31,313.8c-.612,0-1.159-.133-1.642-.398-.483-.267-.865-.663-1.145-1.19-.28-.527-.42-1.178-.42-1.953,0-.788.14-1.445.42-1.97.279-.525.661-.921,1.145-1.187.482-.267,1.029-.399,1.642-.399.602,0,1.143.133,1.624.399.48.266.859.661,1.137,1.187.278.524.417,1.182.417,1.97,0,.775-.139,1.426-.417,1.953-.277.527-.656.924-1.137,1.19-.481.266-1.022.398-1.624.398Zm-.042-1.162c.387,0,.722-.093,1.004-.279s.499-.459.651-.815c.151-.357.228-.785.228-1.285,0-.765-.155-1.357-.466-1.777s-.757-.63-1.341-.63c-.392,0-.729.094-1.011.283-.283.188-.5.462-.651.819-.151.356-.228.792-.228,1.305,0,.752.156,1.336.469,1.754s.761.626,1.345.626Z"
                    fill="#fff"
                  />
                  <path
                    d="m44.758,314.675l3.549-11.375h1.357l-3.569,11.375h-1.337Z"
                    fill="#fff"
                  />
                  <path
                    d="m55.755,304.701v7.756h3.843v1.162h-5.173v-8.918h1.33Z"
                    fill="#fff"
                  />
                  <path
                    d="m61.899,305.45c-.266,0-.475-.073-.626-.221-.152-.146-.228-.347-.228-.599,0-.242.075-.435.228-.577.151-.143.36-.214.626-.214.262,0,.468.071.62.214.151.143.228.335.228.577,0,.252-.076.452-.228.599-.152.147-.358.221-.62.221Zm-.665,8.169v-6.755h1.33v6.755h-1.33Z"
                    fill="#fff"
                  />
                  <path
                    d="m67.935,313.8c-.551,0-1.064-.146-1.54-.44-.477-.294-.859-.707-1.148-1.239s-.434-1.157-.434-1.876c0-.756.145-1.396.434-1.922.289-.524.672-.926,1.148-1.203.476-.278.989-.417,1.54-.417.405,0,.768.064,1.085.192.317.129.59.301.818.519.229.217.414.461.557.731s.241.551.298.84l-1.352.069c-.098-.35-.27-.631-.515-.843-.244-.213-.547-.318-.906-.318-.303,0-.589.085-.857.255-.269.171-.485.43-.65.777-.166.348-.249.787-.249,1.319,0,.495.077.917.231,1.267.153.351.363.619.63.806.266.187.564.279.896.279.477,0,.832-.115,1.067-.346.236-.231.354-.55.354-.956h1.352c0,.472-.11.896-.329,1.274-.22.378-.535.678-.948.899s-.907.332-1.48.332Z"
                    fill="#fff"
                  />
                  <path
                    d="m72.785,313.619v-9.569h1.33v9.569h-1.33Zm4.718,0v-3.948c0-.583-.139-1.025-.417-1.326-.277-.301-.651-.451-1.123-.451-.373,0-.699.102-.977.304-.277.203-.492.5-.644.89-.152.39-.228.864-.228,1.424h-.477c0-.825.106-1.521.318-2.086.213-.564.518-.992.914-1.284s.872-.438,1.428-.438c.541,0,1,.121,1.376.364.375.242.661.587.857,1.032.195.445.294.97.294,1.571v3.948h-1.323Z"
                    fill="#fff"
                  />
                  <path
                    d="m80.45,307.788v-.903h4.347v.903h-4.347Zm3.066,6.013c-.383,0-.729-.072-1.036-.217-.309-.145-.552-.362-.731-.654s-.27-.659-.27-1.103v-6.776h1.323v6.734c0,.635.296.952.889.952.182,0,.359-.02.532-.06.172-.04.368-.1.588-.179l.188.945c-.247.103-.491.188-.731.255-.24.068-.491.102-.752.102Z"
                    fill="#fff"
                  />
                  <path
                    d="m88.941,314.675l3.549-11.375h1.357l-3.569,11.375h-1.337Z"
                    fill="#fff"
                  />
                  <path
                    d="m101.575,313.8c-.606,0-1.164-.108-1.673-.325s-.918-.54-1.229-.97c-.311-.429-.473-.966-.486-1.609h1.302c0,.387.095.704.283.951.189.248.44.431.753.55s.658.179,1.036.179c.382,0,.721-.059,1.015-.175.294-.117.521-.273.683-.469.161-.196.241-.421.241-.673,0-.34-.104-.614-.314-.822-.21-.207-.53-.384-.959-.528l-1.659-.616c-.714-.266-1.258-.572-1.631-.92-.374-.348-.561-.832-.561-1.453,0-.704.275-1.277.826-1.719.551-.44,1.325-.661,2.324-.661.947,0,1.684.22,2.208.658.525.438.825,1.036.899,1.792h-1.309c-.065-.383-.244-.687-.535-.91-.292-.224-.713-.336-1.264-.336-.532,0-.947.115-1.246.347-.299.23-.448.521-.448.871,0,.29.095.521.283.693.189.173.484.329.886.469l1.891.693c.648.229,1.154.536,1.519.924s.546.88.546,1.477c0,.504-.131.95-.392,1.337-.262.388-.645.692-1.148.914s-1.117.332-1.841.332Z"
                    fill="#fff"
                  />
                  <path
                    d="m109.801,313.8c-.612,0-1.159-.133-1.642-.398-.483-.267-.865-.663-1.145-1.19-.28-.527-.42-1.178-.42-1.953,0-.788.14-1.445.42-1.97.279-.525.661-.921,1.145-1.187.482-.267,1.029-.399,1.642-.399.602,0,1.143.133,1.624.399.48.266.859.661,1.137,1.187.278.524.417,1.182.417,1.97,0,.775-.139,1.426-.417,1.953-.277.527-.656.924-1.137,1.19-.481.266-1.022.398-1.624.398Zm-.042-1.162c.387,0,.722-.093,1.004-.279s.499-.459.651-.815c.151-.357.228-.785.228-1.285,0-.765-.155-1.357-.466-1.777s-.757-.63-1.341-.63c-.392,0-.729.094-1.011.283-.283.188-.5.462-.651.819-.151.356-.228.792-.228,1.305,0,.752.156,1.336.469,1.754s.761.626,1.345.626Z"
                    fill="#fff"
                  />
                  <path
                    d="m114.868,306.864h1.33v4.172c0,.509.124.894.374,1.155.25.261.601.392,1.054.392.383,0,.72-.101,1.012-.301.291-.2.52-.485.686-.854.166-.368.249-.805.249-1.309h.427c0,.789-.107,1.451-.322,1.988-.215.536-.516.943-.903,1.222-.387.277-.84.416-1.357.416-.495,0-.935-.091-1.319-.273-.386-.182-.687-.463-.903-.843-.217-.381-.325-.872-.325-1.474v-4.291Zm4.704,0h1.337v6.755h-1.337v-6.755Z"
                    fill="#fff"
                  />
                  <path
                    d="m123.64,313.619v-6.734h1.154l.176,1.456v5.278h-1.33Zm4.718,0v-3.948c0-.583-.139-1.025-.417-1.326-.277-.301-.651-.451-1.123-.451-.373,0-.699.102-.977.304-.277.203-.492.5-.644.89-.152.39-.228.864-.228,1.424h-.477c0-.825.106-1.521.318-2.086.213-.564.518-.992.914-1.284s.872-.438,1.428-.438c.541,0,1,.121,1.376.364.375.242.661.587.857,1.032.195.445.294.97.294,1.571v3.948h-1.323Z"
                    fill="#fff"
                  />
                  <path
                    d="m134.649,313.8c-.551,0-1.057-.147-1.519-.444-.462-.296-.829-.711-1.103-1.246-.273-.534-.409-1.163-.409-1.886,0-.719.134-1.342.402-1.869s.624-.935,1.067-1.222.936-.431,1.477-.431c.495,0,.927.12,1.295.36.369.24.655.59.858,1.047.202.457.304,1.015.304,1.673h-.462c0-.406-.081-.75-.245-1.032-.163-.282-.375-.496-.637-.641s-.541-.217-.84-.217c-.35,0-.666.094-.948.283-.283.188-.507.457-.672.805-.166.348-.249.762-.249,1.243,0,.485.083.906.249,1.263.165.357.389.632.672.823.282.191.596.286.941.286.284,0,.559-.075.822-.227.264-.152.48-.37.651-.655.17-.284.255-.623.255-1.015h.462c0,.672-.105,1.239-.318,1.701-.212.462-.498.811-.857,1.046-.359.236-.758.354-1.197.354Zm2.143-.182l-.231-1.617v-7.952h1.337v9.569h-1.105Z"
                    fill="#fff"
                  />
                  <path
                    d="m140.648,315.236l-.973-.707c.803-.821,1.407-1.67,1.812-2.545.406-.875.609-1.783.609-2.727,0-.634-.09-1.253-.27-1.854-.18-.603-.449-1.189-.809-1.761s-.808-1.131-1.344-1.677l.973-.686c.942.85,1.651,1.781,2.125,2.796.474,1.016.71,2.076.71,3.182,0,.733-.104,1.448-.314,2.146-.21.698-.525,1.369-.945,2.013-.42.645-.945,1.251-1.575,1.82Z"
                    fill="#fff"
                  />
                </g>
                <g
                  id="_35_Location_Miete_Security_Gema_Personal_"
                  data-name="35 % Location (Miete / Security / Gema / Personal)"
                >
                  <path
                    d="m751.293,401.748v-2.34c0-.654.09-1.22.271-1.696.18-.478.471-.887.873-1.229.401-.343.933-.637,1.593-.883l2.564-.953c.462-.181.798-.394,1.009-.64.21-.246.314-.543.314-.891,0-.318-.126-.602-.378-.851s-.624-.374-1.116-.374c-.456,0-.849.139-1.179.414-.33.276-.519.67-.567,1.18h-3.186c-.006-.948.215-1.724.661-2.327.447-.603,1.043-1.047,1.787-1.332.743-.284,1.554-.427,2.43-.427.852,0,1.628.155,2.326.468.699.312,1.257.751,1.675,1.318.416.567.625,1.24.625,2.021,0,.618-.111,1.167-.333,1.647-.222.479-.579.907-1.071,1.282s-1.146.715-1.962,1.021l-2.547.963c-.348.132-.586.26-.716.383-.129.123-.193.283-.193.481v.135h6.786v2.628h-9.666Z"
                    fill="#fff"
                  />
                  <path
                    d="m767.781,402.036c-1.122,0-2.082-.156-2.88-.468-.798-.312-1.41-.736-1.836-1.273-.427-.537-.64-1.142-.64-1.813,0-.534.147-.987.441-1.359s.689-.657,1.188-.855c.498-.197,1.054-.297,1.665-.297.336,0,.641.033.914.1.272.065.604.149.994.252v.63c-.414,0-.79.053-1.129.157-.34.105-.607.248-.806.428s-.297.387-.297.621c0,.246.11.463.333.652.222.189.514.338.877.445.363.108.755.162,1.175.162.894,0,1.52-.087,1.876-.261.357-.174.536-.381.536-.621,0-.186-.075-.358-.226-.518-.149-.159-.362-.299-.639-.419l-3.555-1.377c-.427-.168-.866-.401-1.318-.701-.453-.301-.834-.677-1.144-1.13-.309-.453-.463-.985-.463-1.598,0-.72.189-1.332.571-1.836.381-.504.938-.89,1.674-1.156.734-.268,1.63-.4,2.687-.4.906,0,1.735.151,2.488.454s1.354.724,1.805,1.26c.45.537.675,1.157.675,1.858,0,.433-.129.833-.387,1.202s-.624.667-1.099.896c-.474.228-1.034.342-1.683.342-.294,0-.561-.027-.801-.081s-.525-.135-.855-.243v-.783c.3,0,.593-.045.878-.135s.519-.219.702-.387c.183-.168.274-.366.274-.594,0-.265-.086-.483-.257-.657s-.406-.307-.706-.396c-.301-.09-.646-.135-1.035-.135-.36,0-.69.038-.99.112-.3.075-.54.181-.72.315s-.271.299-.271.49c0,.187.064.345.194.478.129.132.334.261.616.387l3.771,1.484c.889.349,1.57.746,2.048,1.193.477.446.716,1.081.716,1.903,0,.702-.209,1.33-.626,1.886-.417.555-1.024.991-1.822,1.309-.798.318-1.771.478-2.916.478Z"
                    fill="#fff"
                  />
                  <path
                    d="m781.218,395.168c-.468,0-.901-.104-1.301-.311-.398-.207-.718-.526-.958-.958-.24-.433-.36-.979-.36-1.639,0-.647.12-1.184.36-1.606s.56-.737.958-.944c.399-.207.833-.311,1.301-.311.486,0,.93.104,1.332.311s.723.521.963.944.36.959.36,1.606c0,.66-.12,1.206-.36,1.639-.24.432-.561.751-.963.958s-.846.311-1.332.311Zm-1.898,6.579l8.234-12.078h2.862l-8.208,12.078h-2.889Zm1.898-8.343c.174,0,.313-.078.419-.234.104-.156.157-.459.157-.909,0-.426-.053-.712-.157-.859-.105-.146-.245-.221-.419-.221-.168,0-.3.074-.396.221-.097.147-.145.434-.145.859,0,.45.048.753.145.909.096.156.228.234.396.234Zm7.335,8.559c-.468,0-.901-.104-1.3-.311-.399-.207-.719-.524-.959-.954-.24-.429-.36-.97-.36-1.624,0-.666.12-1.214.36-1.643s.56-.747.959-.954c.398-.207.832-.311,1.3-.311.475,0,.912.104,1.314.311.401.207.726.525.972.954s.369.977.369,1.643c0,.654-.123,1.195-.369,1.624-.246.43-.57.747-.972.954-.402.207-.84.311-1.314.311Zm0-1.782c.168,0,.303-.074.405-.225.102-.149.153-.444.153-.882s-.052-.736-.153-.896c-.103-.159-.237-.238-.405-.238s-.301.079-.4.238c-.099.159-.148.457-.148.896s.05.732.148.882c.1.15.232.225.4.225Z"
                    fill="#fff"
                  />
                  <path
                    d="m723.781,422.282v9.972h4.941v1.494h-6.651v-11.466h1.71Z"
                    fill="#fff"
                  />
                  <path
                    d="m733.951,433.982c-.786,0-1.489-.171-2.11-.514-.621-.342-1.111-.852-1.472-1.529-.359-.679-.54-1.516-.54-2.512,0-1.014.181-1.858.54-2.533.36-.675.851-1.184,1.472-1.525s1.324-.513,2.11-.513c.774,0,1.471.171,2.088.513.618.342,1.105.851,1.463,1.525.356.675.535,1.52.535,2.533,0,.996-.179,1.833-.535,2.512-.357.678-.845,1.188-1.463,1.529-.617.343-1.313.514-2.088.514Zm-.054-1.494c.498,0,.929-.12,1.292-.36.362-.24.642-.59.837-1.049.194-.459.292-1.009.292-1.651,0-.983-.199-1.746-.599-2.286-.398-.54-.974-.81-1.724-.81-.504,0-.938.121-1.3.364-.363.243-.643.595-.837,1.054-.195.459-.293,1.019-.293,1.678,0,.967.201,1.718.604,2.255.401.537.978.806,1.728.806Z"
                    fill="#fff"
                  />
                  <path
                    d="m743.833,433.982c-.708,0-1.368-.189-1.98-.567-.611-.378-1.104-.909-1.476-1.593-.372-.685-.559-1.488-.559-2.412,0-.972.187-1.796.559-2.471s.864-1.19,1.476-1.548c.612-.357,1.272-.535,1.98-.535.521,0,.986.082,1.395.247s.759.387,1.053.666.533.593.716.94.311.708.383,1.08l-1.737.09c-.126-.45-.347-.812-.661-1.084-.315-.273-.704-.41-1.166-.41-.39,0-.757.109-1.103.329-.345.219-.624.552-.837.999-.213.446-.319,1.012-.319,1.696,0,.636.099,1.179.297,1.629s.468.795.811,1.035c.342.24.726.359,1.151.359.612,0,1.069-.148,1.373-.445.303-.297.454-.706.454-1.229h1.737c0,.606-.142,1.152-.423,1.639-.282.485-.688.871-1.22,1.156s-1.166.428-1.903.428Z"
                    fill="#fff"
                  />
                  <path
                    d="m752.6,433.982c-.727,0-1.329-.128-1.81-.383-.479-.255-.838-.59-1.075-1.004s-.355-.854-.355-1.322c0-.57.146-1.04.437-1.409s.694-.644,1.211-.823c.516-.18,1.109-.27,1.781-.27.462,0,.912.042,1.351.126.438.084.87.194,1.296.333v1.034c-.349-.126-.733-.226-1.156-.301-.424-.075-.818-.113-1.184-.113-.546,0-1.002.108-1.368.324s-.549.582-.549,1.099c0,.378.144.703.432.977.288.272.744.409,1.368.409.456,0,.857-.104,1.206-.311.348-.207.618-.467.81-.778.192-.312.288-.624.288-.937h.414c0,.601-.121,1.153-.364,1.661-.243.507-.596.915-1.058,1.224s-1.021.464-1.674.464Zm2.897-.234l-.216-1.989v-3.608c0-.708-.146-1.223-.44-1.544-.295-.321-.76-.481-1.396-.481-.36,0-.68.053-.958.157-.279.105-.498.263-.657.473s-.238.475-.238.792h-1.891c0-.636.156-1.151.468-1.548.312-.396.749-.686,1.31-.868.562-.184,1.217-.274,1.967-.274.702,0,1.32.115,1.854.346.533.231.949.593,1.246,1.085s.445,1.128.445,1.908v5.553h-1.494Z"
                    fill="#fff"
                  />
                  <path
                    d="m759.078,426.25v-1.161h5.589v1.161h-5.589Zm3.942,7.731c-.492,0-.937-.094-1.332-.279-.396-.186-.71-.467-.94-.842-.231-.375-.347-.848-.347-1.417v-8.712h1.701v8.657c0,.816.381,1.225,1.143,1.225.234,0,.462-.025.685-.077.222-.051.474-.127.756-.229l.243,1.216c-.318.132-.632.241-.94.328-.31.087-.632.131-.968.131Z"
                    fill="#fff"
                  />
                  <path
                    d="m768.142,423.245c-.342,0-.61-.095-.806-.283-.195-.189-.292-.446-.292-.77,0-.312.097-.56.292-.743.195-.183.464-.274.806-.274.336,0,.602.092.796.274.195.184.293.431.293.743,0,.323-.098.58-.293.77-.194.188-.46.283-.796.283Zm-.855,10.503v-8.685h1.71v8.685h-1.71Z"
                    fill="#fff"
                  />
                  <path
                    d="m776.007,433.982c-.786,0-1.489-.171-2.11-.514-.621-.342-1.111-.852-1.472-1.529-.359-.679-.54-1.516-.54-2.512,0-1.014.181-1.858.54-2.533.36-.675.851-1.184,1.472-1.525s1.324-.513,2.11-.513c.774,0,1.471.171,2.088.513.618.342,1.105.851,1.463,1.525.356.675.535,1.52.535,2.533,0,.996-.179,1.833-.535,2.512-.357.678-.845,1.188-1.463,1.529-.617.343-1.313.514-2.088.514Zm-.054-1.494c.498,0,.929-.12,1.292-.36.362-.24.642-.59.837-1.049.194-.459.292-1.009.292-1.651,0-.983-.199-1.746-.599-2.286-.398-.54-.974-.81-1.724-.81-.504,0-.938.121-1.3.364-.363.243-.643.595-.837,1.054-.195.459-.293,1.019-.293,1.678,0,.967.201,1.718.604,2.255.401.537.978.806,1.728.806Z"
                    fill="#fff"
                  />
                  <path
                    d="m782.739,433.748v-8.658h1.485l.225,1.872v6.786h-1.71Zm6.066,0v-5.076c0-.75-.179-1.318-.536-1.705-.356-.388-.838-.581-1.444-.581-.479,0-.898.131-1.255.392-.357.261-.634.643-.828,1.144-.195.501-.293,1.111-.293,1.831h-.612c0-1.062.137-1.956.41-2.682.272-.727.664-1.276,1.174-1.651.511-.375,1.122-.562,1.837-.562.695,0,1.285.155,1.768.468.483.312.851.755,1.103,1.327.253.573.379,1.247.379,2.021v5.076h-1.701Z"
                    fill="#fff"
                  />
                  <path
                    d="m568.956,441.409l.959.686c-.532.546-.977,1.105-1.333,1.677s-.625,1.158-.805,1.761c-.18.602-.27,1.221-.27,1.854,0,.943.2,1.852.602,2.727s1.003,1.724,1.806,2.545l-.959.707c-.947-.859-1.657-1.797-2.128-2.814-.471-1.017-.707-2.071-.707-3.164,0-1.105.236-2.166.707-3.182.471-1.015,1.181-1.946,2.128-2.796Z"
                    fill="#fff"
                  />
                  <path
                    d="m571.7,442.83h1.911l2.828,5.859h-.126l2.828-5.859h1.848v8.918h-1.344v-7.455h.217l-3.052,6.398h-.875l-3.038-6.363h.133v7.42h-1.33v-8.918Z"
                    fill="#fff"
                  />
                  <path
                    d="m584.488,443.579c-.266,0-.475-.073-.626-.221-.151-.146-.228-.347-.228-.599,0-.242.076-.435.228-.577.152-.143.361-.214.626-.214.262,0,.468.071.62.214.152.143.228.335.228.577,0,.252-.076.452-.228.599-.151.147-.358.221-.62.221Zm-.665,8.169v-6.755h1.33v6.755h-1.33Z"
                    fill="#fff"
                  />
                  <path
                    d="m590.487,451.929c-.579,0-1.101-.149-1.568-.447-.467-.299-.836-.715-1.109-1.246-.273-.532-.41-1.148-.41-1.849,0-.741.135-1.378.406-1.907.271-.529.639-.937,1.106-1.222.466-.284.992-.427,1.575-.427.523,0,.971.106,1.344.318.373.213.674.497.903.854.229.356.386.762.473,1.214.086.453.102.92.045,1.4h-4.788v-.771h3.878l-.322.21c.014-.252,0-.504-.039-.756s-.115-.483-.228-.696c-.112-.212-.27-.383-.472-.511-.203-.129-.461-.192-.774-.192-.392,0-.72.098-.983.294-.264.195-.463.458-.598.787-.136.329-.203.689-.203,1.082v.539c0,.434.065.818.196,1.154s.326.602.584.795c.259.193.587.29.983.29.388,0,.708-.091.963-.272.254-.183.412-.455.472-.819h1.358c-.07.438-.231.821-.483,1.148-.252.326-.575.579-.969.759-.395.18-.841.27-1.341.27Z"
                    fill="#fff"
                  />
                  <path
                    d="m594.456,445.917v-.903h4.347v.903h-4.347Zm3.066,6.013c-.382,0-.728-.072-1.036-.217-.308-.145-.552-.362-.731-.654-.18-.292-.27-.659-.27-1.103v-6.776h1.323v6.734c0,.635.296.952.889.952.182,0,.359-.02.532-.06.173-.04.369-.1.588-.179l.189.945c-.248.103-.491.188-.732.255-.24.068-.491.102-.752.102Z"
                    fill="#fff"
                  />
                  <path
                    d="m603.15,451.929c-.579,0-1.101-.149-1.568-.447-.467-.299-.836-.715-1.109-1.246-.273-.532-.41-1.148-.41-1.849,0-.741.135-1.378.406-1.907.271-.529.639-.937,1.106-1.222.466-.284.992-.427,1.575-.427.523,0,.971.106,1.344.318.373.213.674.497.903.854.229.356.386.762.473,1.214.086.453.102.92.045,1.4h-4.788v-.771h3.878l-.322.21c.014-.252,0-.504-.039-.756s-.115-.483-.228-.696c-.112-.212-.27-.383-.472-.511-.203-.129-.461-.192-.774-.192-.392,0-.72.098-.983.294-.264.195-.463.458-.598.787-.136.329-.203.689-.203,1.082v.539c0,.434.065.818.196,1.154s.326.602.584.795c.259.193.587.29.983.29.388,0,.708-.091.963-.272.254-.183.412-.455.472-.819h1.358c-.07.438-.231.821-.483,1.148-.252.326-.575.579-.969.759-.395.18-.841.27-1.341.27Z"
                    fill="#fff"
                  />
                  <path
                    d="m610.234,452.804l3.549-11.375h1.358l-3.57,11.375h-1.337Z"
                    fill="#fff"
                  />
                  <path
                    d="m622.869,451.929c-.607,0-1.165-.108-1.673-.325-.509-.217-.918-.54-1.229-.97-.311-.429-.473-.966-.487-1.609h1.302c0,.387.094.704.283.951.189.248.44.431.753.55.312.119.658.179,1.036.179.383,0,.721-.059,1.015-.175.294-.117.521-.273.683-.469.161-.196.241-.421.241-.673,0-.34-.105-.614-.315-.822-.21-.207-.53-.384-.959-.528l-1.659-.616c-.714-.266-1.258-.572-1.631-.92-.373-.348-.56-.832-.56-1.453,0-.704.275-1.277.826-1.719.551-.44,1.325-.661,2.324-.661.947,0,1.683.22,2.208.658.525.438.825,1.036.899,1.792h-1.309c-.065-.383-.244-.687-.536-.91s-.713-.336-1.263-.336c-.532,0-.948.115-1.246.347-.299.23-.448.521-.448.871,0,.29.095.521.284.693s.484.329.885.469l1.89.693c.648.229,1.155.536,1.519.924.364.388.546.88.546,1.477,0,.504-.13.95-.392,1.337-.261.388-.644.692-1.148.914-.504.222-1.118.332-1.841.332Z"
                    fill="#fff"
                  />
                  <path
                    d="m630.975,451.929c-.579,0-1.101-.149-1.568-.447-.467-.299-.836-.715-1.109-1.246-.273-.532-.41-1.148-.41-1.849,0-.741.135-1.378.406-1.907.271-.529.639-.937,1.106-1.222.466-.284.992-.427,1.575-.427.523,0,.971.106,1.344.318.373.213.674.497.903.854.229.356.386.762.473,1.214.086.453.102.92.045,1.4h-4.788v-.771h3.878l-.322.21c.014-.252,0-.504-.039-.756s-.115-.483-.228-.696c-.112-.212-.27-.383-.472-.511-.203-.129-.461-.192-.774-.192-.392,0-.72.098-.983.294-.264.195-.463.458-.598.787-.136.329-.203.689-.203,1.082v.539c0,.434.065.818.196,1.154s.326.602.584.795c.259.193.587.29.983.29.388,0,.708-.091.963-.272.254-.183.412-.455.472-.819h1.358c-.07.438-.231.821-.483,1.148-.252.326-.575.579-.969.759-.395.18-.841.27-1.341.27Z"
                    fill="#fff"
                  />
                  <path
                    d="m638.296,451.929c-.551,0-1.064-.146-1.54-.44s-.858-.707-1.148-1.239-.434-1.157-.434-1.876c0-.756.145-1.396.434-1.922.29-.524.672-.926,1.148-1.203.476-.278.989-.417,1.54-.417.406,0,.768.064,1.085.192.317.129.59.301.819.519.229.217.414.461.556.731.143.271.242.551.298.84l-1.351.069c-.098-.35-.27-.631-.515-.843-.245-.213-.547-.318-.907-.318-.303,0-.589.085-.857.255-.268.171-.485.43-.651.777s-.249.787-.249,1.319c0,.495.077.917.231,1.267.154.351.364.619.63.806.266.187.565.279.896.279.476,0,.832-.115,1.068-.346.235-.231.354-.55.354-.956h1.351c0,.472-.11.896-.329,1.274-.22.378-.536.678-.949.899s-.906.332-1.48.332Z"
                    fill="#fff"
                  />
                  <path
                    d="m642.979,444.993h1.33v4.172c0,.509.125.894.375,1.155.25.261.601.392,1.054.392.382,0,.72-.101,1.011-.301.292-.2.521-.485.686-.854.166-.368.249-.805.249-1.309h.427c0,.789-.107,1.451-.322,1.988-.214.536-.516.943-.903,1.222-.387.277-.84.416-1.358.416-.495,0-.935-.091-1.32-.273-.385-.182-.686-.463-.903-.843-.217-.381-.326-.872-.326-1.474v-4.291Zm4.704,0h1.337v6.755h-1.337v-6.755Z"
                    fill="#fff"
                  />
                  <path
                    d="m651.75,451.748v-6.755h1.33v6.755h-1.33Zm.966-3.01c0-.863.09-1.581.27-2.152.18-.572.432-.998.756-1.278.324-.279.706-.42,1.145-.42.131,0,.252.01.367.028.113.019.227.045.34.077l-.049,1.323c-.127-.033-.264-.062-.41-.088s-.281-.038-.402-.038c-.383,0-.696.102-.938.304-.243.203-.422.495-.539.875-.117.381-.175.837-.175,1.369h-.364Z"
                    fill="#fff"
                  />
                  <path
                    d="m657.763,443.579c-.266,0-.475-.073-.626-.221-.152-.146-.228-.347-.228-.599,0-.242.075-.435.228-.577.151-.143.36-.214.626-.214.262,0,.468.071.62.214.151.143.228.335.228.577,0,.252-.076.452-.228.599-.152.147-.358.221-.62.221Zm-.665,8.169v-6.755h1.33v6.755h-1.33Z"
                    fill="#fff"
                  />
                  <path
                    d="m660.444,445.917v-.903h4.347v.903h-4.347Zm3.066,6.013c-.383,0-.729-.072-1.036-.217-.309-.145-.552-.362-.731-.654s-.27-.659-.27-1.103v-6.776h1.323v6.734c0,.635.296.952.889.952.182,0,.359-.02.532-.06.172-.04.368-.1.588-.179l.188.945c-.247.103-.491.188-.731.255-.24.068-.491.102-.752.102Z"
                    fill="#fff"
                  />
                  <path
                    d="m665.652,445.013h1.491l2.03,5.572h-.273l1.631-5.572h1.358l-2.556,8.029c-.233.729-.533,1.272-.899,1.635-.366.361-.824.542-1.375.542-.309,0-.604-.039-.886-.119-.282-.079-.57-.195-.864-.35v-1.197c.252.178.494.306.728.386.233.079.477.118.729.118s.468-.075.647-.228c.18-.151.331-.352.455-.602.123-.25.229-.521.318-.815l.203-.665-2.737-6.734Z"
                    fill="#fff"
                  />
                  <path
                    d="m675.676,452.804l3.549-11.375h1.357l-3.569,11.375h-1.337Z"
                    fill="#fff"
                  />
                  <path
                    d="m685.734,451.929c-.719,0-1.369-.188-1.953-.563-.583-.375-1.047-.905-1.393-1.589s-.518-1.487-.518-2.411c0-.751.108-1.419.325-2.002.217-.584.518-1.076.903-1.478.385-.401.831-.704,1.34-.91.509-.205,1.053-.308,1.631-.308.757,0,1.405.159,1.946.477s.987.821,1.337,1.512l-1.315.378c-.28-.388-.588-.678-.925-.872-.336-.193-.704-.29-1.105-.29-.49,0-.941.142-1.354.424s-.743.684-.99,1.204c-.248.52-.371,1.142-.371,1.865,0,.658.104,1.237.312,1.739.207.502.501.894.882,1.176.38.282.829.424,1.348.424.41,0,.779-.094,1.105-.28.327-.187.585-.463.773-.829.189-.366.284-.82.284-1.361h.35c0,.751-.09,1.403-.27,1.956s-.461.981-.844,1.284c-.383.304-.882.455-1.498.455Zm2.472-.182l-.21-2.044v-1.512l-2.143-.057v-.875h3.431v4.487h-1.078Z"
                    fill="#fff"
                  />
                  <path
                    d="m694.17,451.929c-.579,0-1.102-.149-1.568-.447-.467-.299-.836-.715-1.109-1.246-.273-.532-.409-1.148-.409-1.849,0-.741.135-1.378.405-1.907s.64-.937,1.106-1.222c.467-.284.991-.427,1.575-.427.522,0,.971.106,1.344.318.373.213.674.497.903.854.229.356.386.762.472,1.214.087.453.102.92.046,1.4h-4.788v-.771h3.878l-.321.21c.014-.252,0-.504-.039-.756s-.115-.483-.228-.696c-.112-.212-.27-.383-.473-.511-.203-.129-.461-.192-.773-.192-.392,0-.72.098-.983.294-.264.195-.463.458-.599.787-.135.329-.203.689-.203,1.082v.539c0,.434.065.818.196,1.154s.325.602.585.795c.259.193.586.29.983.29.387,0,.708-.091.962-.272.255-.183.412-.455.473-.819h1.358c-.07.438-.231.821-.483,1.148-.252.326-.575.579-.97.759-.394.18-.841.27-1.34.27Z"
                    fill="#fff"
                  />
                  <path
                    d="m699.042,451.748v-6.734h1.154l.147,1.268c.215-.472.51-.831.886-1.078.375-.247.817-.371,1.326-.371.541,0,1,.121,1.376.364.375.242.661.587.857,1.032.195.445.294.97.294,1.571v3.948h-1.323v-3.948c0-.583-.139-1.025-.417-1.326-.277-.301-.651-.451-1.123-.451-.373,0-.699.102-.977.304-.277.203-.492.5-.644.89-.152.39-.228.864-.228,1.424v3.108h-1.33Zm9.331,0v-3.948c0-.583-.14-1.025-.417-1.326s-.649-.451-1.116-.451c-.561,0-.993.219-1.299.657-.306.439-.458,1.069-.458,1.891h-.581c0-.648.062-1.209.188-1.681.126-.471.307-.858.543-1.161.235-.304.518-.529.847-.676.329-.147.696-.221,1.103-.221.541,0,1,.121,1.376.364.375.242.661.587.857,1.032.195.445.294.97.294,1.571v3.948h-1.337Z"
                    fill="#fff"
                  />
                  <path
                    d="m714.183,451.929c-.564,0-1.034-.099-1.407-.297s-.652-.459-.836-.781c-.185-.321-.277-.664-.277-1.028,0-.443.113-.809.34-1.096s.54-.501.941-.641.863-.21,1.386-.21c.359,0,.71.033,1.051.098.34.065.677.152,1.008.26v.805c-.271-.098-.57-.176-.899-.234s-.636-.088-.921-.088c-.425,0-.779.084-1.063.252-.285.168-.428.453-.428.854,0,.294.112.547.336.76.225.212.579.318,1.064.318.354,0,.667-.081.938-.242.271-.16.48-.362.63-.605.149-.242.224-.485.224-.728h.322c0,.467-.095.897-.284,1.291-.188.395-.463.712-.822.952s-.793.36-1.302.36Zm2.254-.182l-.168-1.547v-2.807c0-.551-.114-.951-.343-1.201-.229-.249-.591-.374-1.085-.374-.28,0-.528.041-.746.122-.217.082-.387.204-.511.367-.124.164-.186.369-.186.616h-1.47c0-.494.121-.896.364-1.204.242-.308.582-.533,1.019-.675.436-.143.945-.214,1.529-.214.546,0,1.026.09,1.441.27.416.18.739.461.97.844.231.383.347.877.347,1.483v4.319h-1.162Z"
                    fill="#fff"
                  />
                  <path
                    d="m722.338,452.804l3.549-11.375h1.357l-3.569,11.375h-1.337Z"
                    fill="#fff"
                  />
                  <path
                    d="m731.969,451.748v-8.918h2.989c.994,0,1.759.247,2.296.742.536.494.805,1.178.805,2.051,0,.863-.269,1.541-.805,2.033-.537.493-1.302.739-2.296.739h-1.646v3.353h-1.344Zm1.344-4.515h1.646c.569,0,1.001-.147,1.295-.441s.44-.684.44-1.169c0-.49-.146-.887-.44-1.19-.294-.303-.726-.454-1.295-.454h-1.646v3.255Z"
                    fill="#fff"
                  />
                  <path
                    d="m742.329,451.929c-.579,0-1.102-.149-1.568-.447-.467-.299-.836-.715-1.109-1.246-.273-.532-.409-1.148-.409-1.849,0-.741.135-1.378.405-1.907s.64-.937,1.106-1.222c.467-.284.991-.427,1.575-.427.522,0,.971.106,1.344.318.373.213.674.497.903.854.229.356.386.762.472,1.214.087.453.102.92.046,1.4h-4.788v-.771h3.878l-.321.21c.014-.252,0-.504-.039-.756s-.115-.483-.228-.696c-.112-.212-.27-.383-.473-.511-.203-.129-.461-.192-.773-.192-.392,0-.72.098-.983.294-.264.195-.463.458-.599.787-.135.329-.203.689-.203,1.082v.539c0,.434.065.818.196,1.154s.325.602.585.795c.259.193.586.29.983.29.387,0,.708-.091.962-.272.255-.183.412-.455.473-.819h1.358c-.07.438-.231.821-.483,1.148-.252.326-.575.579-.97.759-.394.18-.841.27-1.34.27Z"
                    fill="#fff"
                  />
                  <path
                    d="m747.201,451.748v-6.755h1.33v6.755h-1.33Zm.966-3.01c0-.863.09-1.581.27-2.152.18-.572.432-.998.756-1.278.324-.279.706-.42,1.145-.42.131,0,.253.01.367.028s.228.045.34.077l-.049,1.323c-.126-.033-.263-.062-.41-.088-.146-.025-.28-.038-.402-.038-.383,0-.695.102-.938.304-.243.203-.423.495-.539.875-.117.381-.175.837-.175,1.369h-.364Z"
                    fill="#fff"
                  />
                  <path
                    d="m754.908,451.929c-.653,0-1.204-.092-1.652-.276-.448-.184-.79-.44-1.025-.77s-.358-.716-.367-1.159h1.253c.019.388.174.675.466.861.291.187.687.28,1.187.28.266,0,.507-.042.724-.126.218-.084.39-.198.519-.343.128-.145.192-.309.192-.49,0-.229-.084-.41-.252-.543s-.438-.251-.812-.354l-1.379-.469c-.551-.187-.973-.435-1.267-.745s-.441-.683-.441-1.117c0-.555.248-1.002.742-1.34.495-.339,1.15-.508,1.967-.508.845,0,1.497.179,1.957.535.459.357.698.805.718,1.341h-1.281c-.033-.266-.165-.47-.396-.612-.231-.143-.563-.214-.998-.214-.405,0-.725.089-.955.267-.231.177-.347.378-.347.602,0,.401.346.7,1.036.896l1.449.511c.551.177.975.406,1.273.686.299.28.448.635.448,1.064,0,.346-.114.672-.343.979-.229.309-.55.56-.963.753s-.896.29-1.452.29Z"
                    fill="#fff"
                  />
                  <path
                    d="m762.349,451.929c-.612,0-1.159-.133-1.642-.398-.483-.267-.865-.663-1.145-1.19-.28-.527-.42-1.178-.42-1.953,0-.788.14-1.445.42-1.97.279-.525.661-.921,1.145-1.187.482-.267,1.029-.399,1.642-.399.602,0,1.143.133,1.624.399.48.266.859.661,1.137,1.187.278.524.417,1.182.417,1.97,0,.775-.139,1.426-.417,1.953-.277.527-.656.924-1.137,1.19-.481.266-1.022.398-1.624.398Zm-.042-1.162c.387,0,.722-.093,1.004-.279s.499-.459.651-.815c.151-.357.228-.785.228-1.285,0-.765-.155-1.357-.466-1.777s-.757-.63-1.341-.63c-.392,0-.729.094-1.011.283-.283.188-.5.462-.651.819-.151.356-.228.792-.228,1.305,0,.752.156,1.336.469,1.754s.761.626,1.345.626Z"
                    fill="#fff"
                  />
                  <path
                    d="m767.585,451.748v-6.734h1.154l.176,1.456v5.278h-1.33Zm4.718,0v-3.948c0-.583-.139-1.025-.417-1.326-.277-.301-.651-.451-1.123-.451-.373,0-.699.102-.977.304-.277.203-.492.5-.644.89-.152.39-.228.864-.228,1.424h-.477c0-.825.106-1.521.318-2.086.213-.564.518-.992.914-1.284s.872-.438,1.428-.438c.541,0,1,.121,1.376.364.375.242.661.587.857,1.032.195.445.294.97.294,1.571v3.948h-1.323Z"
                    fill="#fff"
                  />
                  <path
                    d="m778.119,451.929c-.564,0-1.034-.099-1.407-.297s-.652-.459-.836-.781c-.185-.321-.277-.664-.277-1.028,0-.443.113-.809.34-1.096s.54-.501.941-.641.863-.21,1.386-.21c.359,0,.71.033,1.051.098.34.065.677.152,1.008.26v.805c-.271-.098-.57-.176-.899-.234s-.636-.088-.921-.088c-.425,0-.779.084-1.063.252-.285.168-.428.453-.428.854,0,.294.112.547.336.76.225.212.579.318,1.064.318.354,0,.667-.081.938-.242.271-.16.48-.362.63-.605.149-.242.224-.485.224-.728h.322c0,.467-.095.897-.284,1.291-.188.395-.463.712-.822.952s-.793.36-1.302.36Zm2.254-.182l-.168-1.547v-2.807c0-.551-.114-.951-.343-1.201-.229-.249-.591-.374-1.085-.374-.28,0-.528.041-.746.122-.217.082-.387.204-.511.367-.124.164-.186.369-.186.616h-1.47c0-.494.121-.896.364-1.204.242-.308.582-.533,1.019-.675.436-.143.945-.214,1.529-.214.546,0,1.026.09,1.441.27.416.18.739.461.97.844.231.383.347.877.347,1.483v4.319h-1.162Z"
                    fill="#fff"
                  />
                  <path
                    d="m784.062,451.748v-9.569h1.33v9.569h-1.33Z"
                    fill="#fff"
                  />
                  <path
                    d="m788.149,453.365l-.973-.707c.803-.821,1.407-1.67,1.812-2.545.406-.875.609-1.783.609-2.727,0-.634-.09-1.253-.27-1.854-.18-.603-.449-1.189-.809-1.761s-.808-1.131-1.344-1.677l.973-.686c.942.85,1.651,1.781,2.125,2.796.474,1.016.71,2.076.71,3.182,0,.733-.104,1.448-.314,2.146-.21.698-.525,1.369-.945,2.013-.42.645-.945,1.251-1.575,1.82Z"
                    fill="#fff"
                  />
                </g>
                <text
                  transform="translate(749.988 16.464)"
                  fill="#fff"
                  font-size="18"
                >
                  <tspan font-family="Karla-ExtraBold, Karla" font-weight="700">
                    <tspan x="0" y="0">
                      30
                    </tspan>
                    <tspan x="23.913" y="0" letter-spacing="-.069em">
                       
                    </tspan>
                    <tspan x="27.513" y="0">
                      %
                    </tspan>
                  </tspan>
                  <tspan font-family="Karla-Medium, Karla" font-weight="500">
                    <tspan x="-93.221" y="32">
                      Künstler:innen
                    </tspan>
                    <tspan x="30.339" y="32" letter-spacing="-.044em">
                       
                    </tspan>
                    <tspan x="33.938" y="32">
                      /
                    </tspan>
                  </tspan>
                  <tspan font-family="Karla-Medium, Karla" font-weight="500">
                    <tspan x="-118.114" y="56">
                      DJs
                    </tspan>
                    <tspan x="-88.981" y="56" letter-spacing="-.044em">
                       
                    </tspan>
                    <tspan x="-85.382" y="56" letter-spacing="0em">
                      /
                    </tspan>
                    <tspan x="-77.309" y="56" letter-spacing="-.044em">
                       
                    </tspan>
                    <tspan x="-73.709" y="56">
                      Performances
                    </tspan>
                  </tspan>
                </text>
              </g>
              <g id="text-2" data-name="text">
                <text
                  transform="translate(328.312 305.836)"
                  fill="#fff"
                  font-family="Argithea, Argithea"
                  font-size="32"
                >
                  <tspan x="0" y="0">
                    Ei
                  </tspan>
                  <tspan x="34.188" y="0" letter-spacing="-.068em">
                    n
                  </tspan>
                  <tspan x="54.609" y="0" letter-spacing="-.017em">
                    t
                  </tspan>
                  <tspan x="71.203" y="0">
                    ritt*
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
          <p className="footnote">
            * Datenerhebung auf Basis der Ausgaben von Luft&Laune am 13.10.22.
          </p>
          <p>
            Luft&Laune wird von vielen ehrenamtlichen Schultern getragen, der
            gesamte Erlös wird in die Events zurückgeführt.
          </p>
        </section>
      </main>
      <ScrolltoTopbutton></ScrolltoTopbutton>
    </div>
  );
}
